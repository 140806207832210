import React from "react";
import { Grid, Card, Typography, Box, Avatar, IconButton } from "@mui/material";
import { useDrop } from "react-dnd";
import avatar from "../../../assets/images/svg/avatar.svg";
import { BASE_URL } from "../../../constants";
import { Close } from "@mui/icons-material";
import { $crud } from "../../../utils/CrudFactory";

const TopReviewClient = ({ reviews, setReviews, onDrop }) => {
  const [, drop] = useDrop(() => ({
    accept: "review",
    drop: (item) => onDrop(item),
  }));

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/top-client-reviews");
    return data;
  }

  const handleDelete = async (id) => {
    const { type } = await $crud.delete("admin/top-client-review", { _id: id });
    if (type == "success") {
      retrieveData().then((res) => {
        setReviews(res.reviews);
      });
    }
  };

  return (
    <Grid container spacing={2} ref={drop}>
      {reviews.length === 0 ? (
        <Grid item xs={12}>
          <Card
            sx={{
              position: "relative",
              padding: "18px",
              height: "260px",
              width: "100%",
              background: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                width: "100%",
                color: "#A3A3A3",
              }}
            >
              No top client reviews found
            </Typography>
          </Card>
        </Grid>
      ) : (
        reviews.map((review, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card
              sx={{
                position: "relative",
                padding: "18px",
                height: "260px",
                background: "#fff",
              }}
            >
              <IconButton
                onClick={() => handleDelete(review._id)}
                sx={{
                  position: "absolute",
                  right: "8px",
                  top: "8px",
                  color: "rgba(104, 60, 140)",
                  "&:hover": {
                    background: "rgba(104, 60, 140, 0.35)",
                  },
                }}
              >
                <Close />
              </IconButton>
              <Box
                sx={{
                  padding: "0 30px",
                  display: "flex",
                  marginTop: "30px",
                  marginBottom: "22px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img src={`${BASE_URL}/clientReviews/${review.img}`} />
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#171A1F",
                      fontWeight: 600,
                    }}
                  >
                    {review.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "8px",
                      color: "#6A6A6A",
                      fontWeight: 400,
                    }}
                  >
                    Date of experience: {review.date}
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: "9px",
                  color: "#171A1F",
                  fontWeight: 400,
                  lineHeight: "14px",
                }}
              >
                {review.content}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "18px",
                  background: "#683C8C",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "90%",
                  padding: "17px 33px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Avatar src={avatar} sx={{ width: "44px" }} />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#fff",
                        fontWeight: 600,
                      }}
                    >
                      {review.author}
                    </Typography>
                    <Typography sx={{ fontSize: "9px", color: "#BDC1CA" }}>
                      {review.designation}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img src={`${BASE_URL}/clientReviews/${review.icon}`} />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#fff",
                      fontWeight: 400,
                    }}
                  >
                    4.5
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default TopReviewClient;