import {
  Box,
  Grid,
  Select,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";
import {
  EditNote,
  Visibility,
  StarBorder,
  Add,
  Delete,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import DynamicTable from "../../components/DynamicTable";
import { usePagination } from "../../hooks";
import { $crud } from "../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { orderFilters } from "../../utils/filterConfig";
import { BASE_URL } from "../../constants";
import PromoTermModal from "../../components/modals/PromoTermModal";
import DeleteLegalModal from "../../components/modals/DeleteLegalModal";
import DriverContractModal from "../../components/modals/DriverContractModal";

export function DriverContract() {
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [existingData, setExistingData] = useState(null);
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    orderType: "",
    orderStatus: "",
    position: "",
    search: "",
    from: "",
    to: "",
    amountFrom: "",
    amountTo: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  const [id, setId] = useState(null);
  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS

  const handleOpenModal = (data, viewMode = false) => {
    setExistingData(data); // Set existing data for the modal
    setView(viewMode); // Set view mode
    setOpen(true);
    setId(data._id);
  };

  const handleClose = () => {
    setOpen(false);
    setId(null);
    setExistingData(null); // Clear existing data when closing modal
  };

  const handleOpenDeleteModal = (_id) => {
    setOpenDelete(true);
    setId(_id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setId(null);
  };
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/driver-contract", {
      limit,
      page,
    });
    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  const handleSubmit = async (content) => {
    console.log("Submitted content:", content, id);
    const { data, type } = id
      ? await $crud.put(`admin/driver-contract`, { content, _id: id })
      : await $crud.post(`admin/driver-contract`, { content });
    if (type == "success") {
      retrieveData().then((res) => {
        setRows(res.result);
        setTotal(res.total);
      });
      handleClose();
    }
  };

  const handleDelete = async (id) => {
    const { type } = await $crud.delete(`admin/driver-contract`, { _id: id });
    if (type == "success") {
      retrieveData().then((res) => {
        setRows(res.result);
        setTotal(res.total);
      });
      handleCloseDelete();
    }
  };

  // Helper function to truncate text
  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    { id: "title", label: "Title", align: "center" },
    {
      id: "content",
      label: "Content",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{ fontSize: "12px" }}
            dangerouslySetInnerHTML={{ __html: truncateText(row.content, 100) }}
          />
        );
      },
    },
    {
      id: "content",
      label: "Created By",
      align: "center",
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title={row.createdBy.name} arrow>
              <Avatar
                src={`${BASE_URL}/adminProfiles/${row.createdBy.profile}`}
              />
            </Tooltip>
          </Box>
        );
      },
    },
    { id: "createdAt", label: "Date", align: "center" },
    {
      id: "action",
      align: "center",
      label: "Actions",
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <IconButton
              sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
              onClick={() => handleOpenModal(row, true)}
            >
              <Visibility />
            </IconButton>
            <IconButton
              sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
              onClick={() => handleOpenModal(row, false)}
            >
              <EditNote />
            </IconButton>
            <IconButton
              sx={{ fontSize: "8px", color: "rgba(255, 49, 49, 1)" }}
              onClick={() => handleOpenDeleteModal(row._id)}
            >
              <Delete />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              color: "#673C8E", // Return the value directly
            }}
          >
            Driver contract
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: "right", paddingRight: "24px" }}
        >
          <Button
            onClick={() => setOpen(!open)}
            disabled={rows.length > 0 ? true : false}
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              ml: 2,
              backgroundColor: "#683C8C",
              color: "#FFFFFF",
              borderColor: "#683C8C",

              "&:hover": {
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                borderColor: "#683C8C",
              },
            }}
            startIcon={<Add />}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ paddingRight: "24px" }}>
        <DynamicTable
          rows={rows}
          type="legal"
          url="clients"
          setRows={setRows}
          filterConfig={orderFilters} // Pass the filter checkbox options
          noRecordTitle="No Driver contract yet !" // no data title
          onFilterData={getFilteredData} // filteration data
          onResetFilters={() => {
            setFiltrationData((prev) => ({
              search: prev.search,
              orderType: "",
              orderStatus: "",
              position: "",
              from: "",
              to: "",
              amountTo: "",
              amountFrom: "",
            }));
          }}
          onSearchData={(value) =>
            setFiltrationData({ ...filtrationData, search: value })
          }
          paginationProps={{ limit, page, setLimit, setPage, total }}
          columns={clientColumns} // Pass the columns prop
        />
      </Box>
      {open && (
        <DriverContractModal
          open={open}
          onClose={handleClose}
          onSubmit={handleSubmit}
          view={view}
          id={id}
          existingData={existingData} // Pass existing data to modal
        />
      )}
      {openDelete && (
        <DeleteLegalModal
          open={openDelete}
          onClose={handleCloseDelete}
          onSubmit={handleDelete}
          title="Driver contract ?"
          id={id}
        />
      )}
    </>
  );
}
