import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  StarOutlined,
  Visibility,
  StarBorder,
  ArrowDropDown,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import OrderPageCard from "../../components/cards/OrderPageCard";
import DynamicTable from "../../components/DynamicTable";
import { usePagination } from "../../hooks";
import { $crud } from "../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Filter from "../../components/Filter";
import { clientFilters } from "../../utils/filterConfig";
import ClientStatusModal from "../../components/modals/ClientStatusModal";

export function Clients() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState(""); // state to open position modal
  const [statusModalOpen, setStatusModalOpen] = useState(false); // state to open position modal
  const [statusPlaceholder, setStatusPlaceholder] = useState(""); // state to open position modal
  const [statusBtn, setStatusBtn] = useState(""); // state to open position modal
  const [statusModalTitle, setStatusModalTitle] = useState(""); //state to display position modal title
  const [selectedRow, setSelectedRow] = useState(null); // To store the selected row for which position is being updated
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    from: "",
    to: "",
    amountFrom: "",
    amountTo: "",
    blockStatus: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS

  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/clients", {
      limit,
      page,
      from: filtrationData.from,
      to: filtrationData.to,
      amountFrom: filtrationData.amountFrom,
      amountTo: filtrationData.amountTo,
      blockStatus: filtrationData.blockStatus,
    });
    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  // console.log(filtrationData);
  // FUNCTION FOR CUSTOM STYLE OF CURRENT STATUS DROPDOWN BUTTONS------------------
  const getStatusStyles = (status) => {
    switch (status) {
      case "Blocked":
        return {
          background: "rgba(254, 133, 106, 0.2)",
          color: "rgba(254, 133, 106, 1)",
          fontSize: "12px",
        };
      case "Active":
        return {
          background: "rgba(7, 79, 36, 0.2)",
          color: "rgba(7, 79, 36, 1)",
          fontSize: "12px",
        };
      default:
        return {
          background: "#FFF0ED",
          color: "rgba(254, 133, 106, 1)",
        };
    }
  };
  // FUNCTION FOR CUSTOM STYLE OF CURRENT STATUS DROPDOWN BUTTONS----------------------

  // function to add data in position modal-----------------------------
  const handleStatusChange = (row, newStatus) => {
    setSelectedRow(row);
    // console.log(newStatus, "=============================");
    setNewStatus(newStatus);
    switch (newStatus) {
      case "Blocked":
        setStatusModalTitle("Add a reason to block");
        setStatusPlaceholder("Enter comments to block");
        setStatusBtn("Block");
        break;
      case "Active":
        setStatusModalTitle("Add a reason to unblock");
        setStatusPlaceholder("Enter comments to unblock");
        setStatusBtn("Unblock");
        break;
      default:
        setStatusModalTitle("");
        break;
    }
    setStatusModalOpen(true);
  };

  // function to close position modal---------------------------------
  const handleCloseModal = () => {
    setStatusModalTitle(false);
    setSelectedRow(null);
    setStatusModalOpen("");
  };

  // function to call the api and update the comment -------------------------------------
  const handleStatusSubmit = async (reason) => {
    // Logic to update position with reason
    console.log(
      `Updating status for order ${selectedRow._id} with reason: ${reason}`
    );
    const { data, type } = await $crud.put(`admin/client`, {
      blockStatus: newStatus,
      comment: reason,
      _id: selectedRow._id,
    });
    console.log(data);
    if (type == "success") {
      retrieveData().then((res) => {
        setRows(res.result);
        setTotal(res.total);
      });
    }
    handleCloseModal();
  };

  const statusOptions = ["Blocked", "Active"];
  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    {
      id: "customerID",
      label: "Customer ID",
      align: "center",
    },
    { id: "name", label: "Customer Name", align: "center" },
    {
      id: "joinDate",
      label: "Join Date",
      align: "center",
    },
    { id: "location", label: "Home Location", align: "center" },
    {
      id: "totalSpent",
      label: "Total Spent",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography sx={{ fontSize: "12px" }}>
            ${row.totalSpent || 0}.00
          </Typography>
        );
      },
    },
    {
      id: "lastOrderPrice",
      label: "Last Order",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography sx={{ fontSize: "12px" }}>
            ${row.lastOrderPrice || 0}.00
          </Typography>
        );
      },
    },
    {
      id: "blockStatus",
      label: "Block Status",
      align: "center",
      renderCell: (row) => {
        const activeStatus = row.blockStatus;
        const styles = getStatusStyles(activeStatus);

        return (
          <Select
            value={activeStatus}
            // onChange={(e) =>
            //   handleStatusChange(row.orderID, "currentStatus", e.target.value)
            // }
            sx={{
              borderColor: styles.background,
              background: styles.background,
              color: styles.color,
              border: "none",
              outline: "none !important", // Remove outline
              fontSize: "12px",
              borderRadius: 0,
              "& .MuiSelect-icon": {
                color: styles.color, // Hide the down arrow icon
              },
              "&:hover": {
                outline: styles.background, // Remove hover background
              },
              "&:focus": {
                outline: styles.background, // Remove focus background
              },
              "& .MuiSelect-select": {
                paddingRight: "32px !important",
                padding: "8px 10px",
                width: "50px",
              },
            }}
            onChange={(e) => handleStatusChange(row, e.target.value)}
          >
            {statusOptions.map((status) => (
              <MenuItem
                key={status}
                value={status}
                sx={getStatusStyles(status)}
              >
                {status}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },

    {
      id: "action",
      label: "View",
      renderCell: (row) => {
        return (
          <IconButton
            sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
            onClick={() =>
              navigate(`/client-detail`, { state: { selectedClient: row } })
            }
          >
            <Visibility />
          </IconButton>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    if (!data.blockStatus) data.blockStatus = "";
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
    setPage(0);
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------

  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              // color: "#673C8E",
              color: theme.palette.text.primary,
            }}
          >
            Clients
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              fontSize: "13px",
              color: "#A3A3A3", // Return the value directly
            }}
          >
            Here is your general customers list data
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
          <Filter
            onResetFilters={() => {
              setFiltrationData((prev) => ({
                from: "",
                to: "",
                amountTo: "",
                amountFrom: "",
                blockStatus: "",
              }));
            }}
            onFilterData={getFilteredData}
            type="clients"
            url="client"
            config={clientFilters}
          />
        </Grid>
      </Grid>

      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE CLIENTS LIST IN TABLE ===========================*/}
      <DynamicTable
        rows={rows}
        type="clients"
        url="clients"
        setRows={setRows}
        filterConfig={clientFilters} // Pass the filter checkbox options
        noRecordTitle="No Clients Yet !" // no data title
        onFilterData={getFilteredData} // filteration data
        onResetFilters={() => {
          setFiltrationData((prev) => ({
            from: "",
            to: "",
            amountTo: "",
            amountFrom: "",
          }));
        }}
        onSearchData={(value) =>
          setFiltrationData({ ...filtrationData, search: value })
        }
        paginationProps={{ limit, page, setLimit, setPage, total }}
        columns={clientColumns} // Pass the columns prop
      />
      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE ORDER LIST IN TABLE ===========================*/}

      {statusModalOpen && selectedRow && (
        <ClientStatusModal
          ifOrder={true}
          open={statusModalOpen}
          title={statusModalTitle}
          onSubmit={handleStatusSubmit}
          onClose={handleCloseModal}
          btnText={statusBtn}
          placeholder={statusPlaceholder}
        />
      )}
    </>
  );
}
