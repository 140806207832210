import {
  Box,
  Grid,
  Select,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";
import {
  EditNote,
  Visibility,
  StarBorder,
  Add,
  StarOutlined,
  Delete,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import DynamicTable from "../components/DynamicTable";
import { usePagination } from "../hooks";
import { $crud } from "../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { orderFilters } from "../utils/filterConfig";
import { BASE_URL } from "../constants";
import BusinessClientModal from "../components/modals/BusinessClientModal";
import DeleteLegalModal from "../components/modals/DeleteLegalModal";
import avatarIcon from "../assets/images/svg/avatar.svg";

export function BusinessClientStories() {
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [existingData, setExistingData] = useState(null);
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    orderType: "",
    orderStatus: "",
    position: "",
    search: "",
    from: "",
    to: "",
    amountFrom: "",
    amountTo: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  const [id, setId] = useState(null);
  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS
  const [searchInput, setSearchInput] = useState("");

  const handleOpenModal = (data, viewMode = false) => {
    setExistingData(data); // Set existing data for the modal
    setView(viewMode); // Set view mode
    setOpen(true);
    setId(data._id);
  };

  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/business-client-stories", {
      limit,
      page,
      search: filtrationData.search,
    });
    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  const handleClose = () => {
    setOpen(false);
    setId(null);
    setExistingData(null); // Clear existing data when closing modal
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
  };

  const handleSearchInputChange = (value) => {
    if (value.trim() === "") {
      setFiltrationData((prevData) => ({
        ...prevData,
        search: "",
      }));
      setSearchInput("");
    }
    setSearchInput(value);
    setFiltrationData((prevData) => ({
      ...prevData,
      search: value,
    }));
  };

  const handleSubmit = async (content) => {
    // console.log("Submitted content:", content, id);
    // const { data, type } = id
    //   ? await $crud.put(`admin/privacy-policy`, { content, _id: id })
    //   : await $crud.post(`admin/privacy-policy`, { content });
    // if (type == "success") {
    //   retrieveData().then((res) => {
    //     setRows(res.result);
    //     setTotal(res.total);
    //   });
    //   handleClose();
    // }
  };

  const handleSpecial = async (id, special) => {
    console.log("Submitted content:", id);
    const { data, type } = await $crud.put(`admin/special-business-client`, {
      id,
      special,
    });
    if (type == "success") {
      retrieveData().then((res) => {
        setRows(res.result);
        setTotal(res.total);
      });
      handleClose();
    }
  };

  const handleOpenDeleteModal = (_id) => {
    setOpenDelete(true);
    setId(_id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setId(null);
  };

  const handleDelete = async (id) => {
    const { type } = await $crud.delete(`admin/business-story`, { _id: id });
    if (type == "success") {
      retrieveData().then((res) => {
        setRows(res.result);
        setTotal(res.total);
      });
      handleCloseDelete();
    }
  };

  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    {
      id: "special",
      label: "Homepage",
      align: "center",
      renderCell: (row) => {
        return (
          <>
            {row.special ? (
              <StarOutlined
                onClick={() => handleSpecial(row._id, row.special)}
                sx={{ color: "#F7C604" }}
              />
            ) : (
              <StarBorder
                onClick={() => handleSpecial(row._id, row.special)}
                sx={{ color: "#F7C604" }}
              />
            )}
          </>
        );
      },
    },
    { id: "title", label: "Title", align: "center" },
    { id: "subTitle", label: "Subtitle", align: "center" },
    { id: "description", label: "Description", align: "center" },
    {
      id: "content",
      label: "Created By",
      align: "center",
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title={row.createdBy.name} arrow>
              <Avatar src={row.createdBy.profile || avatarIcon} />
            </Tooltip>
          </Box>
        );
      },
    },
    { id: "createdAt", label: "Date", align: "center" },
    {
      id: "action",
      align: "center",
      label: "Action",
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <IconButton
              sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
              onClick={() => handleOpenModal(row, true)}
            >
              <Visibility />
            </IconButton>
            <IconButton
              sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
              onClick={() => handleOpenModal(row, false)}
            >
              <EditNote />
            </IconButton>
            <IconButton
              sx={{ fontSize: "8px", color: "rgba(255, 49, 49, 1)" }}
              onClick={() => handleOpenDeleteModal(row._id)}
            >
              <Delete />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              color: "#673C8E", // Return the value directly
            }}
          >
            Business Client Stories
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: "right", paddingRight: "24px" }}
        >
          <Button
            onClick={() => setOpen(!open)}
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              ml: 2,
              backgroundColor: "#683C8C",
              color: "#FFFFFF",
              borderColor: "#683C8C",

              "&:hover": {
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                borderColor: "#683C8C",
              },
            }}
            startIcon={<Add />}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ paddingRight: "24px" }}>
        <DynamicTable
          rows={rows}
          type="businessStory"
          url="business-client-stories"
          searchPlaceholderData={searchInput}
          onSearchData={handleSearchInputChange}
          setRows={setRows}
          filtrationData={filtrationData}
          filterConfig={orderFilters} // Pass the filter checkbox options
          noRecordTitle="No Business Client Stories yet !" // no data title
          onFilterData={getFilteredData} // filteration data
          onResetFilters={() => {
            setFiltrationData((prev) => ({
              search: prev.search,
              orderType: "",
              orderStatus: "",
              position: "",
              from: "",
              to: "",
              amountTo: "",
              amountFrom: "",
            }));
          }}
          paginationProps={{ limit, page, setLimit, setPage, total }}
          columns={clientColumns} // Pass the columns prop
        />
      </Box>
      {open && (
        <BusinessClientModal
          open={open}
          onClose={handleClose}
          onSubmit={handleSubmit}
          view={view}
          id={id}
          row={existingData} // Pass existing data to modal
        />
      )}
      {openDelete && (
        <DeleteLegalModal
          open={openDelete}
          onClose={handleCloseDelete}
          onSubmit={handleDelete}
          title="Business Client Story ?"
          id={id}
        />
      )}
    </>
  );
}
