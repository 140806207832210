import React, { useState, useMemo, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Button,
  Typography,
  Collapse,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  FileUploadOutlined,
  Add,
  EditNote,
  Delete,
  VisibilityOff,
} from "@mui/icons-material";
import Filter from "./Filter";
import SearchComponent from "./Search";
import { useNavigate } from "react-router-dom";
import CustomPagination from "./CustomPagination";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE_URL } from "../constants";
import Export from "./Export";
import sort from "../assets/images/svg/sort.svg";
import play from "../assets/images/svg/play.svg";
import TutorialVideoBox from "./modals/TutorialVideoBox";
import { $crud } from "../utils/CrudFactory";

const DynamicTable = (props) => {
  const theme = useTheme();
  const {
    rows,
    otherRow,
    setRows,
    title,
    type,
    url,
    noRecordTitle,
    onFilterData,
    onSearchData,
    onResetFilters,
    filtrationData,
    filterConfig,
    paginationProps: { limit, page, setPage, setLimit, total, setTotal },
    columns,
    searchPlaceholderData,
    expandedRows,
    handleExpandRow,
    isFilter,
  } = props;

  // Utility function to format the date in the desired style
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty date strings

    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, " ");
  };

  const navigate = useNavigate();
  const [totalData, setTotalData] = useState([]);
  async function retrieveData() {
    const { data } = await $crud.retrieve(`admin/${url}`);
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setTotalData(res.result);
    });
  }, []);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [sortedData, setSortedData] = useState([]); // For holding sorted data when sorting all
  const [isSorting, setIsSorting] = useState(false);

  const parseDate = (dateString) => {
    // Parse the date string into a Date object
    return new Date(dateString);
  };

  const getNestedValue = (obj, path) => {
    return path
      .split(".")
      .reduce(
        (acc, part) => (acc && acc[part] !== undefined ? acc[part] : null),
        obj
      );
  };
  const sortedRows = React.useMemo(() => {
    let sortableRows = [...rows];
    if (sortConfig.key !== null) {
      sortableRows.sort((a, b) => {
        const isDateKey = [
          "dateBooked",
          "joinDate",
          "dateTime",
          "startDate",
          "expireDate",
          "createdAt",
        ].includes(sortConfig.key);

        const isAlphabeticalKey = [
          "name",
          "location",
          "offerCode",
          "title",
          "subTitle",
          "description",
          "tags",
          "tag",
          "chatParty1.name",
          "chatParty2.name",
          "currentStatus.status", // Added
          "position.status", // Added
        ].includes(sortConfig.key);

        const valueA = isDateKey
          ? parseDate(a[sortConfig.key])
          : isAlphabeticalKey
          ? getNestedValue(a, sortConfig.key)?.toLowerCase() || ""
          : a[sortConfig.key];

        const valueB = isDateKey
          ? parseDate(b[sortConfig.key])
          : isAlphabeticalKey
          ? getNestedValue(b, sortConfig.key)?.toLowerCase() || ""
          : b[sortConfig.key];

        if (valueA < valueB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRows;
  }, [rows, sortConfig]);
  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setIsSorting(true); // Set sorting as active when sorting is applied

    // If `totalData` has been fetched (all data), sort all and set it
    if (totalData.length > rows.length) {
      let sorted = [...totalData].sort((a, b) => {
        const isDateKey = [
          "dateBooked",
          "joinDate",
          "dateTime",
          "startDate",
          "expireDate",
          "createdAt",
        ].includes(key);

        const isAlphabeticalKey = [
          "name",
          "location",
          "offerCode",
          "title",
          "subTitle",
          "description",
          "tags",
          "tag",
          "chatParty1.name",
          "chatParty2.name",
          "currentStatus.status", // Added to check sorting for currentStatus.status
          "position.status", // Added to check sorting for position.status
        ].includes(key);

        let valueA, valueB;

        if (key === "currentStatus.status") {
          valueA = a.currentStatus?.status?.toLowerCase() || "";
          valueB = b.currentStatus?.status?.toLowerCase() || "";
        } else if (key === "position.status") {
          valueA = a.position?.status?.toLowerCase() || "";
          valueB = b.position?.status?.toLowerCase() || "";
        } else if (isDateKey) {
          valueA = parseDate(a[key]);
          valueB = parseDate(b[key]);
        } else if (isAlphabeticalKey) {
          valueA = getNestedValue(a, key)?.toLowerCase() || "";
          valueB = getNestedValue(b, key)?.toLowerCase() || "";
        } else {
          valueA = a[key];
          valueB = b[key];
        }

        if (valueA < valueB) {
          return direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedData(sorted);
    } else {
      setSortedData([]);
    }
  };

  // FUNCTION TO CHNAGE THE TABLE PAGE -------------------------------
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  // FUNCTION TO CHNAGE THE TABLE PAGE -------------------------------

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  // FUNCTION TO EXPORT THE TABLE DATA --------------------------------------
  const handleExportToPDF = () => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Set document properties
    doc.setProperties({
      title: "Table Data Export",
    });

    // Get table data
    const tableData = [];
    // Push header row
    const headerRow = columns.map((column) => column.label);
    tableData.push(headerRow);
    // Push data rows
    rows.forEach((row) => {
      const rowData = [];
      columns.forEach((column) => {
        // Check if the column is 'currentstatus' or 'position'
        if (column.id === "currentStatus" || column.id === "position") {
          // Push the status from the respective object directly
          rowData.push(row[column.id].status);
        } else {
          // Push other columns as usual
          rowData.push(row[column.id]);
        }
      });
      tableData.push(rowData);
    });

    // Auto table content
    doc.autoTable({
      head: [tableData[0]], // Header row
      body: tableData.slice(1), // Data rows
    });

    // Save the PDF
    doc.save("table_data.pdf");
  };

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true); // Start playing the video
  };

  return (
    <Grid container p={2} maxWidth={"100vw"}>
      {type !== "orderDetails" && type !== "legal" && (
        <Grid item xs={12} md={6} mb={2}>
          {/* ============================== CALLING SEARCH COMPONENT ================================== */}
          <SearchComponent
            type={type}
            onSearchData={onSearchData}
            padding="2px"
            borderRadius="8px"
            placeholder={
              type === "orders"
                ? "Search orders by ID’s"
                : type === "tutorials"
                ? "Search"
                : "Search chat by Name and ID’s"
            }
            value={searchPlaceholderData}
          />
          {/* ============================== CALLING SEARCH COMPONENT ================================== */}
        </Grid>
      )}

      {(type === "clientStatement" ||
        type === "driverStatement" ||
        type === "executiveStatement") && (
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          {/* Left side: Date Range */}
          <Grid item xs={12} md={6}>
            <b>Selected Range</b>: From {formatDate(filtrationData.from)} to{" "}
            {formatDate(filtrationData.to)}
          </Grid>
          {/* Right side: Filter and Export Buttons */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: "right",
            }}
          >
            <Filter
              onResetFilters={onResetFilters}
              onFilterData={onFilterData}
              url={url}
              type={type}
              config={filterConfig}
            />
            <Export columns={columns} rows={rows} type={type} />
          </Grid>
        </Grid>
      )}

      {/* Updated condition: Exclude clientStatement, driverStatement, and executiveStatement */}
      {type !== "orderDetails" &&
        type !== "clientStatement" &&
        type !== "driverStatement" &&
        type !== "executiveStatement" && (
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: "right",
              display:
                type === "clients" ||
                type === "drivers" ||
                type === "legal" ||
                type === "moreWays" ||
                type === "businessStory"
                  ? "none"
                  : "",
            }}
            mb={2}
          >
            {/* ================================ CALLING FILTER COMPONENT ================================ */}
            <Filter
              onResetFilters={onResetFilters}
              onFilterData={onFilterData}
              url={url}
              type={type}
              config={filterConfig}
            />
            {/* ================================ CALLING FILTER COMPONENT ================================ */}

            {/* =========================  EXPORT TABLE DATA BUTTON===================== */}
            {type === "orders" && (
              <Button
                onClick={handleExportToPDF}
                variant="contained"
                size="small"
                sx={{
                  textTransform: "capitalize",
                  ml: 2,
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                  borderColor: "#683C8C",
                  "&:hover": {
                    backgroundColor: "#683C8C",
                    color: "#FFFFFF",
                    borderColor: "#683C8C",
                  },
                }}
                startIcon={<FileUploadOutlined />}
              >
                Export
              </Button>
            )}
          </Grid>
        )}
      <Grid item xs={12}>
        <Paper elevation={2} sx={{ width: "100%" }}>
          {/* =================================== MAIN TABLE CONTAINER ===================================== */}
          <TableContainer
            sx={{
              height: rows.length >= 5 ? "380px" : "",
              // height: !isSorting && rows.length >= 5 ? "380px" : "auto",
              maxWidth: "100vw",
              boxShadow: (theme) => theme.shadows[5],
              borderRadius: 0,
              position: "relative", // Ensure that the container is positioned relatively
            }}
          >
            <Table
              size="small"
              aria-label="a dense table"
              sx={{ borderRadius: "0", height: "100%" }}
              stickyHeader
              // aria-label=" sticky table"
            >
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        background: "#683C8C",
                        color: "#fff",
                        cursor: "pointer",
                        padding: "16px 6px",
                      }}
                      // onClick={() => {
                      //   requestSort(column.id);
                      //   console.log(`${column.id}.name`);
                      // }}
                      onClick={() => {
                        console.log(column.id);
                        const sortKey =
                          column.id === "chatParty1" ||
                          column.id === "chatParty2"
                            ? `${column.id}.name`
                            : column.id === "currentStatus"
                            ? `${column.id}.status`
                            : column.id === "position"
                            ? `${column.id}.status`
                            : column.id;

                        requestSort(sortKey);
                      }}
                    >
                      {column.label}
                      {column.label != "View" &&
                        column.label != "Action" &&
                        column.label != "Case" &&
                        column.label != "Description" &&
                        column.label != "Subtitle" &&
                        column.label != "Between" &&
                        column.label != "Delete" &&
                        column.label != "Created By" && (
                          <img
                            src={sort}
                            style={{
                              marginLeft: "10px",
                              width: "10px",
                              height: "10px",
                            }}
                          />
                        )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {sortedRows.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      sx={{ borderBottom: "none", p: 0 }}
                    >
                      <Box
                        sx={{
                          height: "200px", // Ensure it matches the height of the container
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 1)" }}
                        >
                          {noRecordTitle}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {(sortedData.length > 0 ? sortedData : sortedRows)?.map(
                    (row) => (
                      <>
                        <TableRow
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              (type === "regularOffers" ||
                                type === "helpOffers") &&
                              expandedRows.includes(row._id)
                                ? "rgba(221, 202, 238, 0.35)"
                                : "transparent",
                            transition: "background-color 0.3s ease",
                          }}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                          onClick={() =>
                            type === "orderDetails"
                              ? row.type == "Dropoff"
                                ? navigate(`/order-detail/dropoff-details`, {
                                    state: {
                                      pickup: row,
                                      selectedOrder: otherRow,
                                    },
                                  })
                                : navigate(`/order-detail/pickup-details`, {
                                    state: {
                                      pickup: row,
                                      selectedOrder: otherRow,
                                    },
                                  })
                              : ""
                          }
                        >
                          {columns?.map((column) => {
                            const value = row[column.id];

                            return (
                              <TableCell
                                sx={{
                                  color: theme.palette.text.secondary,
                                  fontSize: "12px",
                                  // backgroundColor:
                                  //   (type == "regularOffers" ||
                                  //     type == "helpOffers") &&
                                  //   expandedRows.includes(row.code)
                                  //     ? "#f5f5f5"
                                  //     : "transparent",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {column?.renderCell
                                  ? column?.renderCell(row)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {(type == "regularOffers" || type == "helpOffers") &&
                          expandedRows.includes(row._id) &&
                          (type === "regularOffers" ||
                            type === "helpOffers") && (
                            <TableRow>
                              <TableCell
                                colSpan={columns.length + 1}
                                sx={{ padding: 0 }}
                              >
                                {/* Render additional details for the expanded row */}
                                <Collapse
                                  in={expandedRows.includes(row._id)}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      padding: "0 34px",
                                      paddingTop: "34px",
                                      paddingBottom: 0,
                                      backgroundColor:
                                        "rgba(221, 202, 238, 0.35)",
                                      display: "flex",
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                      gap: "20px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "4px",
                                        right: "36px",
                                      }}
                                    >
                                      <IconButton
                                        sx={{
                                          fontSize: "8px",
                                          color: "rgba(104, 60, 140, 1)",
                                        }}
                                        onClick={() =>
                                          navigate(`add?id=${row._id}`)
                                        }
                                        // Add your onClick logic for edit here
                                      >
                                        <EditNote />
                                      </IconButton>
                                      {/* <IconButton
                                      sx={{
                                        fontSize: "8px",
                                        color: "rgba(104, 60, 140, 1)",
                                      }}
                                      onClick={() => handleExpandRow(row._id)}
                                      // Add your onClick logic for delete here
                                    >
                                      <VisibilityOff />
                                    </IconButton> */}
                                    </Box>
                                    <Box>
                                      <Box
                                        sx={{
                                          width: "260px",
                                          height: "120px",
                                          background: "#673C8E",
                                          mb: 1,
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "inherit",
                                            height: "inherit",
                                          }}
                                          src={
                                            type == "regularOffers"
                                              ? BASE_URL +
                                                "regularOffers/" +
                                                row.bannerImg
                                              : BASE_URL +
                                                "helpOffers/" +
                                                row.bannerImg
                                          }
                                        />
                                      </Box>
                                      <Typography
                                        sx={{
                                          textAlign: "center",
                                          fontSize: "20px",
                                          fontWeight: 600,
                                          color: "rgba(242, 132, 118, 1)",
                                        }}
                                      >
                                        Offer Preview
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Box
                                        sx={{
                                          display: "grid",
                                          gridTemplateColumns: "150px 10px 1fr",
                                          gap: 1,
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            color: "#000",
                                          }}
                                        >
                                          Created on
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            color: "#000",
                                          }}
                                        >
                                          :
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            color: "#000",
                                          }}
                                        >
                                          {row.createdOn}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "grid",
                                          gridTemplateColumns: "150px 10px 1fr",
                                          gap: 1,
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            color: "#000",
                                          }}
                                        >
                                          Discount details
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            color: "#000",
                                          }}
                                        >
                                          :
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            color: "#000",
                                          }}
                                        >
                                          {row.discountDetails}
                                        </Typography>
                                      </Box>

                                      {type == "regularOffers" && (
                                        <>
                                          <Box
                                            sx={{
                                              display: "grid",
                                              gridTemplateColumns:
                                                "150px 10px 1fr",
                                              gap: 1,
                                              alignItems: "start",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                color: "#000",
                                              }}
                                            >
                                              Description
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                color: "#000",
                                              }}
                                            >
                                              :
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                color: "#000",
                                              }}
                                            >
                                              {row.description}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "grid",
                                              gridTemplateColumns:
                                                "150px 10px 1fr",
                                              gap: 1,
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                color: "#000",
                                              }}
                                            >
                                              Target
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                color: "#000",
                                              }}
                                            >
                                              :
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                color: "#000",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "14px",
                                                  color: "#000",
                                                }}
                                              >
                                                {JSON.parse(row.validFor).map(
                                                  (v, index, array) =>
                                                    index === array.length - 1
                                                      ? v
                                                      : v + ", "
                                                )}
                                              </Typography>
                                            </Typography>
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                    <Box sx={{ marginRight: "40px" }}>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: 500,
                                          color: "#000",
                                        }}
                                      >
                                        {type == "regularOffers"
                                          ? "Terms and conditions"
                                          : "Offer Details"}
                                      </Typography>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            row.termCondition ||
                                            row.offerDetails,
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}

                        {type == "tutorials" &&
                          expandedRows.includes(row._id) &&
                          type === "tutorials" && (
                            <TableRow>
                              <TableCell
                                colSpan={columns.length + 1}
                                sx={{ padding: 0 }}
                              >
                                {/* Render additional details for the expanded row */}
                                <Collapse
                                  in={expandedRows.includes(row._id)}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      padding: "16px 34px",
                                      paddingBottom: 0,
                                      backgroundColor:
                                        "rgba(221, 202, 238, 0.35)",
                                      gap: "20px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        bottom: "20px",
                                        right: "60px",
                                      }}
                                    >
                                      <Button
                                        onClick={() =>
                                          navigate(`add?id=${row._id}`)
                                        }
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          padding: "8px 36px",
                                          textTransform: "capitalize",
                                          backgroundColor:
                                            "rgba(104, 60, 140, 0.35)",
                                          color: "rgba(103, 60, 142, 1)",
                                          borderRadius: "20px",
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(104, 60, 140, 0.35)",
                                            color: "rgba(103, 60, 142, 1)",
                                          },
                                        }}
                                        endIcon={<EditNote />}
                                      >
                                        Edit
                                      </Button>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                        width: "50%",
                                      }}
                                    >
                                      <TutorialVideoBox
                                        key={row._id}
                                        row={row}
                                        BASE_URL={BASE_URL}
                                        playIcon={play}
                                      />

                                      <Box>
                                        <Typography
                                          sx={{
                                            fontSize: "18px",
                                            color: "#673C8E",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Description
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            color: "#000",
                                          }}
                                        >
                                          {row.description}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                      </>
                    )
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* =================================== MAIN TABLE CONTAINER ===================================== */}

          {/* ===================================== CUSTOM PAGINATION ================================== */}

          {/* {type !== "orderDetails" && type !== "legal" && !isSorting && (
            <CustomPagination
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              total={total}
            />
          )} */}

          {type !== "orderDetails" &&
            type !== "legal" &&
            !isSorting &&
            !isFilter && (
              <CustomPagination
                count={total}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                total={total}
              />
            )}

          {/* ===================================== CUSTOM PAGINATION ================================== */}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DynamicTable;
