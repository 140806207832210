// LOCAL
// export const API_URL = "http://localhost:9000/api/";
// export const BASE_URL = "http://localhost:9000/";

// export const API_URL = "https://12b4-2405-201-6800-d089-fc72-3f57-80f5-1e51.ngrok-free.app/api/";
// export const BASE_URL = "https://12b4-2405-201-6800-d089-fc72-3f57-80f5-1e51.ngrok-free.app";

//  QA
// export const API_URL = "https://apiqa.vpickup.com/api/";
// export const BASE_URL = "https://apiqa.vpickup.com/";

//  DEVELOPMENT
export const API_URL = "https://apiqa.vpickup.com/api/";
export const BASE_URL = "https://apiqa.vpickup.com/";



