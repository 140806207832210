// icons.js
import logo from "./assets/images/logo.svg";
import logoPurple from "./assets/images/logoPurple.svg";
import home from "./assets/images/svg/Home.svg";
import order from "./assets/images/svg/pie.svg";
import report from "./assets/images/svg/report.svg";
import client from "./assets/images/svg/client.svg";
import driver from "./assets/images/svg/driver.svg";
import calendar from "./assets/images/svg/calendar.svg";
import chat from "./assets/images/svg/chat.svg";
import offer from "./assets/images/svg/offer.svg";
import tutorial from "./assets/images/svg/tutorial.svg";
import review from "./assets/images/svg/review.svg";
import account from "./assets/images/svg/account.svg";
import statement from "./assets/images/svg/statement.svg";
import help from "./assets/images/svg/help.svg";
import logoutIcon from "./assets/images/svg/logout.svg";
import logoutWhite from "./assets/images/svg/logoutWhite.svg";
import deleteAccount from "./assets/images/svg/deleteAccount.svg";
import homeWhite from "./assets/images/svg/homeWhite.svg";
import orderWhite from "./assets/images/svg/pieWhite.svg";
import clientWhite from "./assets/images/svg/clientWhite.svg";
import driverWhite from "./assets/images/svg/driverWhite.svg";
import offerWhite from "./assets/images/svg/offerWhite.svg";
import chatWhite from "./assets/images/svg/chatWhite.svg";
import helpWhite from "./assets/images/svg/helpWhite.svg";
import accountWhite from "./assets/images/svg/accountWhite.svg";
import deleteAccountWhite from "./assets/images/svg/deleteAccountWhite.svg";
import statementWhite from "./assets/images/svg/statementWhite.svg";
import reviewWhite from "./assets/images/svg/reviewWhite.svg";
import tutorialWhite from "./assets/images/svg/tutorialWhite.svg";
import calendarWhite from "./assets/images/svg/calendarWhite.svg";
import reportWhite from "./assets/images/svg/reportWhite.svg";
import privacy from "./assets/images/svg/privacy.svg";
import terms from "./assets/images/svg/terms.svg";
import promo from "./assets/images/svg/promo.svg";
import acceptable from "./assets/images/svg/acceptable.svg";
import outArea from "./assets/images/svg/outArea.svg";
import businessP from "./assets/images/svg/businessP.svg";
import business from "./assets/images/svg/business.svg";
import promoWhite from "./assets/images/svg/promoWhite.svg";


// Map of icons
export const icons = {
    privacy,
    businessP,
    business,
    promoWhite,
    terms,
    promo,
    acceptable,
    outArea,
    logo,
    logoPurple,
    home,
    order,
    report,
    client,
    driver,
    calendar,
    chat,
    offer,
    tutorial,
    review,
    account,
    statement,
    help,
    logoutIcon,
    logoutWhite,
    deleteAccount,
    homeWhite,
    orderWhite,
    clientWhite,
    driverWhite,
    offerWhite,
    chatWhite,
    helpWhite,
    accountWhite,
    deleteAccountWhite,
    statementWhite,
    reviewWhite,
    tutorialWhite,
    calendarWhite,
    reportWhite,
};
