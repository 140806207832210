import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopReviewClient from "./TopReviewClient";
import OtherReviewClient from "./OtherReviewClient";
import { $crud } from "../../../utils/CrudFactory";

const ClientReviews = ({ showAllReviews, onViewAllClick, filtrationData }) => {
  const [topReviews, setTopReviews] = useState([]);
  const [filteredTopReviews, setFilteredTopReviews] = useState([]);
  const [otherReviews, setOtherReviews] = useState([]);
  const [filteredOtherReviews, setFilteredOtherReviews] = useState([]);

  const addReviewToTop = async (review) => {
    // Optimistically update the state
    setTopReviews((prevReviews) => {
      if (prevReviews.some((r) => r.id === review.id)) {
        return prevReviews;
      }
      if (prevReviews.length < 3) {
        return [...prevReviews, review];
      }
      const updatedReviews = [review, ...prevReviews.slice(0, 2)];
      return updatedReviews;
    });

    // Re-fetch data to ensure it's in sync with the server
    const updatedData = await retrieveTopReviews();
    setTopReviews(updatedData.reviews);
  };

  async function retrieveTopReviews() {
    const { data } = await $crud.retrieve("admin/top-client-reviews", {
      from: filtrationData.from,
      to: filtrationData.to,
    });
    return data;
  }

  async function retrieveOtherReviews() {
    const { data } = await $crud.retrieve("admin/client-reviews", {
      from: filtrationData.from,
      to: filtrationData.to,
    });
    return data;
  }

  useEffect(() => {
    retrieveTopReviews().then((res) => {
      setTopReviews(res.reviews);
    });
    retrieveOtherReviews().then((res) => {
      setOtherReviews(res.reviews);
    });
  }, [filtrationData]);

  useEffect(() => {
    if (filtrationData.from || filtrationData.to) {
      const filtered = topReviews.filter((review) => {
        const reviewDate = new Date(review.date);
        const fromDate = filtrationData.from
          ? new Date(filtrationData.from)
          : null;
        const toDate = filtrationData.to ? new Date(filtrationData.to) : null;

        if (fromDate && toDate) {
          return reviewDate >= fromDate && reviewDate <= toDate;
        } else if (fromDate) {
          return reviewDate >= fromDate;
        } else if (toDate) {
          return reviewDate <= toDate;
        }
        return true;
      });
      setFilteredTopReviews(filtered);
    } else {
      setFilteredTopReviews(topReviews);
    }
  }, [filtrationData, topReviews]);

  useEffect(() => {
    if (filtrationData.from || filtrationData.to) {
      const filtered = otherReviews.filter((review) => {
        const reviewDate = new Date(review.date);
        const fromDate = filtrationData.from
          ? new Date(filtrationData.from)
          : null;
        const toDate = filtrationData.to ? new Date(filtrationData.to) : null;

        if (fromDate && toDate) {
          return reviewDate >= fromDate && reviewDate <= toDate;
        } else if (fromDate) {
          return reviewDate >= fromDate;
        } else if (toDate) {
          return reviewDate <= toDate;
        }
        return true;
      });
      setFilteredOtherReviews(filtered);
    } else {
      setFilteredOtherReviews(otherReviews);
    }
  }, [filtrationData, otherReviews]);

  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        {!showAllReviews && (
          <Grid item xs={12}>
            <TopReviewClient
              reviews={filteredTopReviews}
              setReviews={setTopReviews}
              onDrop={addReviewToTop}
            />
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <OtherReviewClient
            reviews={filteredOtherReviews}
            onDrop={addReviewToTop}
            showAllReviews={showAllReviews}
            onViewAllClick={onViewAllClick}
            filtrationData={filtrationData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ClientReviews;
