import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import {
  StarOutlined,
  Visibility,
  StarBorder,
  ArrowDropDown,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import OrderPageCard from "../../components/cards/OrderPageCard";
import DynamicTable from "../../components/DynamicTable";
import { usePagination } from "../../hooks";
import { $crud } from "../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Filter from "../../components/Filter";
import { orderFilters, reportfilter } from "../../utils/filterConfig";

const reportrows = [
  {
    reportname: "YOY Growth Report",
    reportdescription:
      "Compares current year performance with the previous year to highlight growth trends",
  },
  {
    reportname: "Available Active Drivers",
    reportdescription:
      "Tracks the number of active drivers available for service during a specific period",
  },
  {
    reportname: "Last Month Profit Report",
    reportdescription:
      "Summarizes profit earned in the previous month, including revenue and expenses",
  },
  {
    reportname: "Last Year Report",
    reportdescription:
      "Provides a summary of key metrics from the previous year for performance evaluation",
  },
];

export function Reports() {
  const navigate = useNavigate();

  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    orderType: "",
    orderStatus: "",
    position: "",
    search: "",
    from: "",
    to: "",
    amountFrom: "",
    amountTo: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  const [rows, setRows] = useState(reportrows); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS

  const [search, setsearch] = useState("");

  const filteredbysearch = rows.filter((row) => {
    return JSON.stringify(row).includes(search);
  });

  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/orders", {
      limit,
      page,
      search: filtrationData.search,
      position: filtrationData.position,
      orderType: filtrationData.orderType,
      orderStatus: filtrationData.orderStatus,
      from: filtrationData.from,
      to: filtrationData.to,
      amountFrom: filtrationData.amountFrom,
      amountTo: filtrationData.amountTo,
    });
    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    // retrieveData().then((res) => {
    //   setRows(res.result);
    //   setTotal(res.total);
    // });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  // FUNCTION FOR CUSTOM STYLE OF CURRENT STATUS DROPDOWN BUTTONS------------------
  const getStatusStyles = (status) => {
    switch (status) {
      case "New Order":
        return {
          background: "rgba(255, 240, 237, 1)",
          color: "rgba(254, 133, 106, 1)",
        };
      case "On Delivery":
        return {
          background: "rgba(213, 229, 244, 1)",
          color: "rgba(76, 170, 224, 1)",
        };
      case "Delivered":
        return {
          background: "rgba(217, 243, 234, 1)",
          color: "rgba(42, 189, 139, 1)",
        };
      default:
        return {
          background: "#FFF0ED",
          color: "rgba(254, 133, 106, 1)",
        };
    }
  };
  // FUNCTION FOR CUSTOM STYLE OF CURRENT STATUS DROPDOWN BUTTONS----------------------

  // FUNCTION TO GET THE ACTIVE STATUS OF POSITIONA ND CURRENT STATUS COLUMNS ------------------
  const getActiveStatus = (statuses) => {
    return statuses.find((status) => status.active)?.status || "";
  };
  // FUNCTION TO GET THE ACTIVE STATUS OF POSITIONA ND CURRENT STATUS COLUMNS ------------------

  // FUNCTION TO UPDATE THE SPECIAL ORDERS------------------------------
  const handleSpecialOrderUpdate = async (orderID, specialOrder) => {
    try {
      const response = await $crud.put("/admin/specialOrder", {
        orderID,
        specialOrder,
      });

      if (response.status === 200) {
        // Update the rows in the state with the updated order
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.orderID === orderID ? { ...row, ...response.data.order } : row
          )
        );
      } else {
        console.error("Failed to update special order:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating special order:", error.message);
    }
  };
  // FUNCTION TO UPDATE THE SPECIAL ORDERS------------------------------

  const statusOptions = ["Blocked", "Active"];
  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    {
      id: "reportname",
      label: "Report Name",
      align: "center",
      renderCell: (row) => {
        return <Typography>{row.reportname}</Typography>;
      },
    },
    // {
    //   id: "reportname",
    //   label: "Report Name",
    //   align: "center",
    //   renderCell: (row) => {
    //     return (
    //       <>
    //         {row.specialOrder ? (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "start",
    //               alignItems: "center",
    //               gap: 2,
    //             }}
    //           >
    //             <StarOutlined
    //               onClick={() =>
    //                 handleSpecialOrderUpdate(row.orderID, row.specialOrder)
    //               }
    //               sx={{ color: "#F7C604" }}
    //             />{" "}
    //             <Typography>{row.reportname}</Typography>
    //           </Box>
    //         ) : (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "start",
    //               alignItems: "center",
    //               gap: 2,
    //             }}
    //           >
    //             <StarBorder
    //               onClick={() =>
    //                 handleSpecialOrderUpdate(row.orderID, row.specialOrder)
    //               }
    //               sx={{ color: "#F7C604" }}
    //             />{" "}
    //             <Typography>{row.reportname}</Typography>
    //           </Box>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      id: "reportdescription",
      label: "Report Description",
      align: "center",
      renderCell: (row) => {
        return <Typography>{row.reportdescription}</Typography>;
      },
    },
    // { id: "customerName", label: "Customer Name", align: "center" },
    // { id: "location", label: "Location", align: "center" },
    // {
    //   id: "amount",
    //   label: "Location",
    //   align: "center",
    //   renderCell: (row) => {
    //     return <Typography>${row.amount}.00</Typography>;
    //   },
    // },
    // {
    //   id: "amount",
    //   label: "Total Spent",
    //   align: "center",
    //   renderCell: (row) => {
    //     return <Typography>${row.amount}.00</Typography>;
    //   },
    // },
    // {
    //   id: "amount",
    //   label: "Last Order",
    //   align: "center",
    //   renderCell: (row) => {
    //     return <Typography>${row.amount}.00</Typography>;
    //   },
    // },
    // {
    //   id: "blockStatus",
    //   label: "Block Status",
    //   align: "center",
    //   renderCell: (row, handleStatusChange) => {
    //     const activeStatus = getActiveStatus(row.blockStatus);
    //     const styles = getStatusStyles(activeStatus);

    //     return (
    //       <Select
    //         value={activeStatus}
    //         // onChange={(e) =>
    //         //   handleStatusChange(row.orderID, "currentStatus", e.target.value)
    //         // }
    //         sx={{
    //           borderColor: styles.background,
    //           background: styles.background,
    //           color: styles.color,
    //           border: "none",
    //           outline: "none !important", // Remove outline
    //           "& .MuiSelect-icon": {
    //             color: styles.color, // Hide the down arrow icon
    //           },
    //           "&:hover": {
    //             outline: styles.background, // Remove hover background
    //           },
    //           "&:focus": {
    //             outline: styles.background, // Remove focus background
    //           },
    //           "& .MuiSelect-select": {
    //             paddingRight: "32px !important",
    //             padding: "8px 10px",
    //             width: "90px",
    //           },
    //         }}
    //       >
    //         {statusOptions.map((status) => (
    //           <MenuItem
    //             key={status}
    //             value={status}
    //             sx={getStatusStyles(status)}
    //           >
    //             {status}
    //           </MenuItem>
    //         ))}
    //         {/* {row.currentStatus.map((status) => (
    //               <MenuItem
    //                 key={status.status}
    //                 value={status.status}
    //                 sx={getStatusStyles(status.status)}
    //               >
    //                 {status.status}
    //               </MenuItem>
    //             ))} */}
    //       </Select>
    //     );
    //   },
    // },

    {
      id: "action",
      label: "Action",
      align: "center",
      renderCell: (row) => {
        return (
          // <IconButton
          //   sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
          //   onClick={() => navigate(`/order-detail?id=${row.orderID}`)}
          // >
          //   <Visibility />
          // </IconButton>
          // <Button onClick={() => navigate("/order-detail")} >Action</Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "20px 20px 20px 20px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
            size="small"
            style={{ backgroundColor: "#673C8E", color: "white" }}
            onClick={() =>
              navigate(
                `/services/reports/reportaction?reportname=${row.reportname.replace(
                  /\s+/g,
                  ""
                )}`
              )
            }
          >
            Run
          </Button>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------

  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              color: "#673C8E", // Return the value directly
            }}
          >
            Reports
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              fontSize: "13px",
              color: "#A3A3A3", // Return the value directly
            }}
          >
            Here is your general report list data
          </Typography>
        </Grid>
      </Grid>

      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE CLIENTS LIST IN TABLE ===========================*/}
      <DynamicTable
        rows={rows}
        type="clients"
        url="clients"
        setRows={setRows}
        filterConfig={orderFilters} // Pass the filter checkbox options
        noRecordTitle="No Reports yet !" // no data title
        onFilterData={getFilteredData} // filteration data
        onResetFilters={() => {
          setFiltrationData((prev) => ({
            search: prev.search,
            orderType: "",
            orderStatus: "",
            position: "",
            from: "",
            to: "",
            amountTo: "",
            amountFrom: "",
          }));
        }}
        onSearchData={(value) =>
          setFiltrationData({ ...filtrationData, search: value })
        }
        paginationProps={{ limit, page, setLimit, setPage, total }}
        columns={clientColumns} // Pass the columns prop
      />
      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE ORDER LIST IN TABLE ===========================*/}
    </>
  );
}
