import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backdropFilter: "blur(2px)",
  },
  dialogPaper: {
    borderRadius: "0",
    border: "1px solid #000",
  },
  textFieldRoot: {
    "& .MuiInputLabel-root": {
      color: "#8D8D8E !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(103, 60, 142, 1)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(103, 60, 142, 1)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(103, 60, 142, 1)",
      },
    },
  },
}));

const AddCategoryModal = ({ onClose, open, onSubmit }) => {
  const classes = useStyles();
  const [categoryName, setCategoryName] = useState("");

  const handleSubmit = async () => {
    if (categoryName.trim()) {
      await onSubmit(categoryName);
      setCategoryName("");
    }
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent sx={{ marginTop: "50px", padding: "0 40px 40px" }}>
          <Typography
            id="modal-title"
            sx={{
              color: "black",
              fontWeight: "bold",
              fontSize: "20px",
              mb: 2,
              textAlign: "start",
            }}
            gutterBottom
          >
            Category Name
          </Typography>
          <TextField
            fullWidth
            label="Enter Category Name"
            variant="outlined"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className={classes.textFieldRoot}
            sx={{ mb: 3 }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                borderColor: "#683C8C",
                color: "#683C8C",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  borderColor: "#683C8C",
                  backgroundColor: "transparent",
                  color: "#683C8C",
                },
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                padding: "6px 40px",
                fontSize: "15px",
                fontWeight: 400,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                },
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Add Item
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCategoryModal;
