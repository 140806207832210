import { Grid, Typography } from "@mui/material";
import React from "react";
import CustomCalendarUI from "../../components/CustomCalendarUI";

export function Calendar() {
  return (
    <Grid container sx={{ padding: "0 18px" }}>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: "30px",
            color: "#673C8E", // Return the value directly
          }}
        >
          Calendar
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomCalendarUI />
      </Grid>
    </Grid>
  );
}
