import React, { useState } from "react";
import filter from "../assets/images/svg/filter.svg";
import filterPurple from "../assets/images/svg/filterPurple.svg";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Typography,
  TextField,
  Slider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Search from "./Search";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AmountRangeFilter from "./filters/AmountRangeFilter";
const dayjs = require("dayjs");

// Custom styled Slider
const CustomSlider = styled(Slider)({
  "& .MuiSlider-valueLabel": {
    backgroundColor: "#673C8E",
    color: "#fff",
  },
  "& .MuiSlider-markLabel": {
    fontSize: "8px", // Decrease font size of marks' labels
  },
});
const Filter = ({ url, config, onFilterData, onResetFilters, type }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  // STATE TO MANAGE SELECTED FILTERS -----------------------
  const [selectedFilters, setSelectedFilters] = useState({
    amountFrom: 10,
    amountTo: 500,
  });
  // STATE TO MANAGE SELECTED FILTERS -----------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // FUNCTION TO CLOSE THE FILTER POPOVER-----------------
  const handleClose = () => {
    setAnchorEl(null);
  };
  // FUNCTION TO CLOSE THE FILTER POPOVER-----------------

  // FUNCTION TO MANAGE SEARCH FILTER--------------
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  // FUNCTION TO MANAGE SEARCH FILTER--------------

  // FUNCTION TO MANAGE OTHER FILTERS ---------------------
  // const handleFilterChange = (key, optionValue) => {
  //   setSelectedFilters((prev) => ({
  //     ...prev,
  //     [key]: optionValue,
  //   }));
  // };
  const handleFilterChange = (key, optionValue) => {
    setSelectedFilters((prev) => {
      const isUnchecked = prev[key] === optionValue;

      const newFilters = {
        ...prev,
        [key]: isUnchecked ? null : optionValue,
      };

      if (isUnchecked) {
        onResetFilters();
      }

      return newFilters;
    });
  };

  // FUNCTION TO MANAGE OTHER FILTERS ---------------------

  // FUNCTION TO MANAGE AMOUNT SLIDER RANGE FILTER-------------------
  const handleSliderChange = (event, newValue) => {
    handleFilterChange("amountFrom", newValue[0]);
    handleFilterChange("amountTo", newValue[1]);
  };
  // FUNCTION TO MANAGE AMOUNT SLIDER RANGE FILTER-------------------

  const valuetext = (value) => {
    return `$${value}`;
  };

  // FUNCTION TO APPLY THE SELECTED FILTERS --------------------------
  const handleApply = () => {
    onFilterData(selectedFilters);
    handleClose();
  };
  // FUNCTION TO APPLY THE SELECTED FILTERS --------------------------

  const open = Boolean(anchorEl);

  // AMOUNT SLIDER RANGE MARKS----------------------
  const marks = [
    { value: 10, label: "$10" },
    { value: 100, label: "$100" },
    { value: 200, label: "$200" },
    { value: 300, label: "$300" },
    { value: 400, label: "$400" },
    { value: 500, label: "$500" },
    { value: 600, label: "$600" },
    { value: 700, label: "$700" },
    { value: 800, label: "$800" },
    { value: 900, label: "$900" },
  ];
  // AMOUNT SLIDER RANGE MARKS----------------------

  return (
    <>
      <Button
        variant={
          type == "clients" || type == "drivers" || type == "reviewFilter"
            ? "outlined"
            : "contained"
        }
        size="small"
        onClick={handleClick}
        sx={{
          textTransform: "capitalize",
          backgroundColor:
            type == "clients" || type == "drivers" || type == "reviewFilter"
              ? "transparent"
              : "#683C8C",
          color:
            type == "clients" || type == "drivers" || type == "reviewFilter"
              ? "#683C8C"
              : "#FFFFFF",
          // mr: 2,
          // display: type == "clients" ? "none" : "",
          borderColor: "#683C8C",
          "&:hover": {
            backgroundColor:
              type == "clients" || type == "drivers" || type == "reviewFilter"
                ? "transparent"
                : "#683C8C",
            color:
              type == "clients" || type == "drivers" || type == "reviewFilter"
                ? "#683C8C"
                : "#FFFFFF",
            borderColor: "#683C8C",
          },
        }}
        startIcon={
          <img
            style={{ width: "20px" }}
            src={
              type == "clients" || type == "drivers" || type == "reviewFilter"
                ? filterPurple
                : filter
            }
            alt="Logo"
          />
        }
        endIcon={
          type == "clients" || type == "drivers" || type == "reviewFilter" ? (
            <ArrowDropDownIcon />
          ) : (
            ""
          )
        }
      >
        Filter
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "15px" }}
      >
        <div
          style={{
            marginTop: "15px",
            maxWidth: "250px",
            padding: "12px",
            height: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "rgba(23, 26, 31, 1)",
                  fontSize: "13px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Filters
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography
                onClick={() => {
                  setSelectedFilters({});
                  onResetFilters(); // RESET ALL THE FILTERS
                }}
                sx={{
                  color: "rgba(23, 26, 31, 1)",
                  fontSize: "13px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Reset
              </Typography>
            </Grid>

            {/* ===========================  DISPLAY THE CHECKBOX FILTERS================================ */}
            {config.filters.map((filterCategory, index) => (
              <Grid item xs={12} key={index} mt={type == "orders" ? 2 : 0}>
                {filterCategory.category && (
                  <Typography
                    sx={{
                      color: "rgba(23, 26, 31, 1)",
                      fontSize: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {filterCategory.category}
                  </Typography>
                )}
                <FormGroup>
                  {filterCategory.options.map((option, idx) => (
                    <FormControlLabel
                      key={idx}
                      sx={{ fontSize: "10px" }}
                      control={
                        <Checkbox
                          sx={{
                            color: "#683C8C",
                            "&.Mui-checked": {
                              color: "#683C8C",
                            },
                          }}
                          checked={
                            selectedFilters[filterCategory.key] === option.value
                          }
                          onChange={() =>
                            handleFilterChange(filterCategory.key, option.value)
                          }
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: "10px" }}>
                          {option.label}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </Grid>
            ))}
            {/* ===========================  DISPLAY THE CHECKBOX FILTERS================================ */}

            {/* AMOUNT RANGE FILTER */}
            <AmountRangeFilter
              type={type}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            {/* AMOUNT RANGE FILTER */}

            {/* ======================================== DATE RANGE FILTER ======================================== */}
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "rgba(23, 26, 31, 1)",
                  fontSize: "10px",
                  mb: 1,
                  fontWeight: 700,
                }}
              >
                {type == "orders" ||
                type == "clientStatement" ||
                type == "executiveStatement" ||
                type == "reviewFilter" ||
                type == "chats" ||
                type == "tutorials"
                  ? "Date Range"
                  : type == "regularOffers" || type == "helpOffers"
                  ? "Date"
                  : type == "driverStatement"
                  ? "Payout Date Range"
                  : "Filter with Join Date"}
              </Typography>

              {/* ============================= FROM DATE ============================== */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(23, 26, 31, 1)",
                    fontSize: "12px",
                    mb: 1,
                  }}
                >
                  {type == "regularOffers" || type == "helpOffers"
                    ? "Start"
                    : "From"}
                  :
                </Typography>
                <Box>
                  <LocalizationProvider
                    sx={{
                      "& .PrivatePickersPopperRoot-popupIndicator": {
                        display: "none",
                      },
                    }}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      value={
                        selectedFilters?.from
                          ? dayjs(selectedFilters.from)
                          : null
                      }
                      // onChange={(newValue) => {
                      //   const formattedDate = dayjs(newValue).toISOString();

                      //   handleFilterChange("from", formattedDate);
                      // }}
                      onChange={(newValue) => {
                        const formattedDate =
                          dayjs(newValue).format("YYYY-MM-DD");
                        handleFilterChange("from", formattedDate);
                      }}
                      PopoverProps={{ disableEnforceFocus: true }} // Disable enforcing focus
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "7px",
                          borderRadius: "14px",
                          border: "none", // Remove the border from the input
                        },
                        "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root":
                          {
                            color: "#673C8E", // Change the color of the icon button
                          },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "14px",
                        },
                        "& .MuiOutlinedInput-root fieldset": {
                          border: "2px solid #673C8E",
                        },
                        "& .MuiOutlinedInput-root fieldset:hover": {
                          borderColor: "#673C8E", // Change border color on hover
                        },
                        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                          borderColor: "#673C8E", // Change border color when focused
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              {/* ============================= FROM DATE ============================== */}

              {/* ============================= TO DATE ============================== */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(23, 26, 31, 1)",
                    fontSize: "12px",
                    mb: 1,
                  }}
                >
                  {type == "regularOffers" || type == "helpOffers"
                    ? "Expiry"
                    : "To"}
                  :
                </Typography>
                <Box sx={{ width: "80%", mt: 1 }}>
                  <LocalizationProvider
                    sx={{
                      "& .PrivatePickersPopperRoot-popupIndicator": {
                        display: "none",
                      },
                    }}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      value={
                        selectedFilters?.to ? dayjs(selectedFilters.to) : null
                      }
                      // onChange={(newValue) => {
                      //   const formattedDate = dayjs(newValue).toISOString();
                      //   handleFilterChange("to", formattedDate);
                      // }}
                      onChange={(newValue) => {
                        const formattedDate =
                          dayjs(newValue).format("YYYY-MM-DD");
                        handleFilterChange("to", formattedDate);
                      }}
                      PopoverProps={{ disableEnforceFocus: true }} // Disable enforcing focus
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "7px",
                          borderRadius: "14px",
                          border: "none", // Remove the border from the input
                        },
                        "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root":
                          {
                            color: "#673C8E", // Change the color of the icon button
                          },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "14px",
                        },
                        "& .MuiOutlinedInput-root fieldset": {
                          border: "2px solid #673C8E",
                        },
                        "& .MuiOutlinedInput-root fieldset:hover": {
                          borderColor: "#673C8E", // Change border color on hover
                        },
                        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                          borderColor: "#673C8E", // Change border color when focused
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              {/* ============================= TO DATE ============================== */}
            </Grid>
            {/* ======================================== DATE RANGE FILTER ======================================== */}
            <Grid item xs={6} sx={{ mb: 1, mt: 2 }}>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                // onClick={() => {
                //   setSelectedFilters({});
                //   onResetFilters(); // RESET ALL THE FILTERS
                // }}
                onClick={handleClose}
                sx={{
                  color: "#683C8C",
                  mr: 2,
                  borderColor: "#683C8C",
                  "&:hover": {
                    color: "#683C8C",
                    borderColor: "#683C8C",
                  },
                }}
              >
                Cancel
              </Button>
            </Grid>

            {/* ============================== APPLY FILTER BUTTON ================================= */}
            <Grid item xs={6} sx={{ textAlign: "right", mb: 1, mt: 2 }}>
              <Button
                variant="contained"
                size="small"
                fullWidth
                onClick={handleApply}
                sx={{
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                  borderColor: "#683C8C",
                  "&:hover": {
                    backgroundColor: "#683C8C",
                    color: "#FFFFFF",
                    borderColor: "#683C8C",
                  },
                }}
              >
                Apply
              </Button>
            </Grid>
            {/* ============================== APPLY FILTER BUTTON ================================= */}
          </Grid>
        </div>
      </Popover>
    </>
  );
};

export default Filter;
