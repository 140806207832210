/* eslint-disable */
import React from "react";
import { Close } from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import { Box, Card } from "@mui/material";
import download from "../assets/images/svg/download.svg";
import { BASE_URL } from "../constants";

export function ImagePreview(props) {
  const { htmlFor, onClick, imgSrc } = props;
  const fileExtension = imgSrc.split(".").pop();
  return (
    <a href={`${BASE_URL}orderImages/${imgSrc}`} target="_blank">
      <Card
        sx={{
          width: "50px",
          height: "56px",
          borderRadius: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img src={download} />
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            bottom: 0,
            background: "rgba(103, 60, 142, 1)",
            color: "#fff",
            fontSize: "10px",
          }}
        >
          .{fileExtension}
        </Box>
      </Card>
    </a>
  );
}

export function PreviewImage(props) {
  const { htmlFor, onClick, src } = props;
  return (
    <label
      className="document-box"
      style={{ border: "none", position: "relative" }}
      htmlFor={htmlFor}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "200px",
          border: "1px dashed black",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        {/* <div
          style={{
            backgroundColor: "#000",
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <Close
            color="primary"

            // onClick={onClick}
          />
        </div> */}

        <img
          style={{ width: "100%", height: "100%", borderRadius: "inherit" }}
          src={src}
        />
      </div>
    </label>
  );
}

export function ImageUploadPreview(props) {
  const { htmlFor, onClick, src } = props;
  return (
    <label
      className="document-box"
      style={{ border: "none", position: "relative" }}
      htmlFor={htmlFor}
    >
      <div
        style={{
          backgroundColor: "#000",
          position: "absolute",
          right: 0,
          top: 0,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <Close color="primary" onClick={onClick} />
      </div>

      <img style={{ width: "100%", height: "100%" }} src={src} />
    </label>
  );
}

export function UploadButton({ name, id }) {
  return (
    <label className="document-box" htmlFor={id}>
      <div className="imageUploader">
        <UploadIcon style={{ fontSize: "40px" }} />
      </div>
    </label>
  );
}

export function ImageUploader(props) {
  const { name, onChange, id, onClick, src, file, required, accept } = props;

  return (
    <React.Fragment>
      <input
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
        required={required}
        name={name}
        accept={accept}
        style={{ display: "none" }}
        id={id}
        type="file"
      />
      {file || src ? (
        <PreviewImage htmlFor={id} src={src} onClick={onClick} file={file} />
      ) : (
        <UploadButton name={name} id={id} />
      )}
    </React.Fragment>
  );
}
