import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import reportimg from "../../assets/images/reportImg.svg";
import excelimg from "../../assets/images/excel-image.png";
import pdflogoimg from "../../assets/images/pdf-logo.jpg";
import { $crud } from "../../utils/CrudFactory";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { useSearchParams } from "react-router-dom";

function ReportAction() {
  const [email, setemail] = useState("");

  const [search, setsearch] = useSearchParams();
  const reportname = search.get("reportname");

  const downloadExcel = async () => {
    // Data for the Excel file

    const { data } = await $crud.send({
      url: "admin/reports/action/exceldownload",
      method: "POST",
      data: { reportname },
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Generate and trigger download of the Excel file
    XLSX.writeFile(workbook, "report.xlsx");
  };

  const downloadPdf = async () => {
    // Define the HTML content
    try {
      const { data: htmlContent } = await $crud.send({
        url: "admin/reports/action/pdfdownload",
        method: "POST",
        data: { reportname },
      });

      const options = {
        filename: "Report.pdf", // Filename for the generated PDF
        html2canvas: { scale: 2 }, // Quality of the canvas rendering
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // Paper size and orientation
      };

      // Generate PDF
      html2pdf().from(htmlContent).set(options).save();
    } catch (e) {
      console.log(e);
    }
  };

  async function handlesendmail() {
    try {
      const { data } = await $crud.send({
        url: "admin/reports/action/sendmail",
        method: "POST",
        data: { to: email, reportname },
      });

      console.log(data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              color: "#673C8E",
            }}
          >
            Reports
          </Typography>
        </Grid>
      </Grid>
      <Container
        maxWidth="sm"
        style={{
          width: "100%",
          height: "fit",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5px",
          }}
        >
          <img src={reportimg} alt="" width={250} height={250} />
        </div>
        <div
          style={{
            display: "flex",
            position: "relative",
            marginTop: "50px",
            height: "39px",
            justifyContent: "center",
          }}
        >
          <TextField
            onChange={(e) => {
              setemail(e.target.value);
            }}
            value={email}
            required
            id="outlined-basic"
            label="Enter email"
            variant="outlined"
            type="email"
            size="small"
            sx={{
              paddingRight: "40px",
              width: "400px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px 0 0 20px",
                "&:hover fieldset": {
                  borderColor: "#673C8E",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#673C8E",
                },
              },
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#673C8E",
              },
            }}
          />
          <Button
            onClick={handlesendmail}
            variant="text"
            size="small"
            sx={{ borderRadius: "0 20px 20px 0" }}
            style={{
              position: "absolute",
              right: "5px",
              backgroundColor: "#673C8E",
              height: "40px",
              padding: "10px",
              color: "white",
              fontSize: "12px",
            }}
          >
            Send to Email
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "50px",
            justifyContent: "center",
            alignItems: "center",
            gap: "45px",
            marginLeft: "20px",
          }}
        >
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={downloadExcel}
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <img src={excelimg} alt="excel-logo" width={30} height={30} />{" "}
            Download the Excel file
          </Typography>
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={downloadPdf}
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <img src={pdflogoimg} alt="pdf-logo" width={30} height={30} />{" "}
            Download the Pdf file
          </Typography>
        </div>
      </Container>
    </>
  );
}

export default ReportAction;
