import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ControlPoint,
  EditAttributes,
  EditOff,
  EditSharp,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";
import { usePagination } from "../../hooks";
import CategoryItemTable from "./EditCategorytable";
import back from "../../assets/images/svg/back.svg";
import DeleteCategoryModal from "../../components/modals/DeleteCategoryModal";

export function EditCategory() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const { state } = useLocation();

  const handleCloseModal = () => {
    setopenDeleteModal(false);
  };

  async function handleDeleteCategory(id) {
    try {
      const res = await $crud.delete(`admin/accepted-category/${id}`);
      navigate("/others/accepted-categories");
    } catch (e) {
      handleCloseModal();
    }
  }

  async function handleDisableCategory() {
    try {
      const res = await $crud.put(`admin/accepted-category/${state._id}`, {
        status: state.status === "Active" ? "Disabled" : "Active",
      });
      navigate("/others/accepted-categories");
    } catch (e) {}
  }

  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: 1,
            }}
          >
            <IconButton
              onClick={() => navigate(-1)}
              sx={{ marginLeft: "-10px" }}
            >
              <img src={back} />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Accepted Categories / {state.name}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: "right", paddingRight: "24px" }}
        ></Grid>
      </Grid>
      <Box sx={{ padding: "24px" }}>
        <CategoryItemTable />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "80px",
          }}
        >
          <Button
            sx={{
              borderColor: "#683C8C",
              color: "#ffffff",
              padding: "6px 40px",
              backgroundColor: "#683C8C",
              fontSize: "15px",
              fontWeight: 400,
              textTransform: "capitalize",
              "&:hover": {
                borderColor: "#683C8C",
                backgroundColor: "transparent",
                color: "#683C8C",
              },
            }}
            variant="outlined"
            onClick={() => setopenDeleteModal(true)}
          >
            Delete
          </Button>
          <Button
            sx={{
              borderColor: "#683C8C",
              color: "#683C8C",
              padding: "6px 40px",
              fontSize: "15px",
              fontWeight: 400,
              textTransform: "capitalize",
              "&:hover": {
                borderColor: "#683C8C",
                backgroundColor: "transparent",
                color: "#683C8C",
              },
            }}
            variant="outlined"
            onClick={handleDisableCategory}
          >
            {state.status === "Active" ? "Disable" : "Enable"}
          </Button>
        </Box>
        {openDeleteModal && (
          <DeleteCategoryModal
            state={state}
            open={openDeleteModal}
            onClose={handleCloseModal}
            onSubmit={handleDeleteCategory}
          />
        )}
      </Box>
    </>
  );
}
