// filterConfig.js
export const statementFilters = {
  searchPlaceholder: "Search",
  filters: [],
};

export const reviewFilter = {
  searchPlaceholder: "Search",
  filters: [],
};

export const clientFilters = {
  searchPlaceholder: "Search",
  filters: [
    {
      category: "Filter with Status",
      key: "blockStatus",
      options: [
        { label: "Blocked customer", value: "Blocked" },
        { label: "Active Customer", value: "Active" },
      ],
    },
  ],
};

export const driverFilters = {
  filters: [
    {
      category: "Filter with Driver Category",
      key: "driverType",
      options: [
        { label: "VIP Drivers", value: "vipDriver" },
        { label: "Regular Drivers", value: "regularDriver" },
      ],
    },
    {
      category: "Filter with Driver Status",
      key: "blockStatus",
      options: [
        { label: "Blocked Drivers", value: "Blocked" },
        { label: "Active Drivers", value: "Active" },
      ],
    },
  ],
};

export const regularOfferFilters = {
  filters: [
    {
      category: "Target",
      key: "target",
      options: [
        { label: "Driver", value: "Drivers" },
        { label: "Client", value: "Clients" },
        { label: "Website", value: "Website" },
      ],
    },
    {
      category: "Stage",
      key: "stage",
      options: [
        { label: "Active", value: "Active" },
        { label: "Expired", value: "Expired" },
        { label: "Archived", value: "Archived" },
      ],
    },
  ],
};

export const helpOfferFilters = {
  filters: [
    {
      category: "Stage",
      key: "stage",
      options: [
        { label: "Active", value: "Active" },
        { label: "Expired", value: "Expired" },
        { label: "Archived", value: "Archived" },
      ],
    },
  ],
};

export const tutorialFilters = {
  filters: [
    {
      category: "Visibility",
      key: "visibility",
      options: [
        { label: "Drivers", value: "Drivers" },
        { label: "Clients", value: "Clients" },
        { label: "Drivers & Clients", value: "Drivers,Clients" },
      ],
    },
    {
      category: "Video Type",
      key: "videoSourceType",
      options: [
        { label: "Link", value: "Link" },
        { label: "Embedded", value: "Embedded" },
      ],
    },
  ],
};

export const orderFilters = {
  searchPlaceholder: "Search",
  filters: [
    {
      category: "With Order Type",
      key: "orderType",
      options: [
        { label: "Regular Orders", value: "regularOrders" },
        { label: "Special Orders", value: "specialOrder" },
      ],
    },
    {
      category: "With Order Status",
      key: "orderStatus",
      options: [
        { label: "Booked", value: "Booked" },
        { label: "Processing", value: "Processing" },
        { label: "Delivered", value: "Delivered" },
      ],
    },
    {
      category: "With Position",
      key: "position",
      options: [
        { label: "Orders with No Holds", value: "No Holds" },
        { label: "Orders Under Review", value: "Review" },
        { label: "Orders Rejected", value: "Reject" },
      ],
    },
  ],
};

export const chatsFilter = {
  filters: [
    {
      key: "selectAll",
      options: [{ label: "Select All", value: "true" }],
    },
    {
      key: "liveChat",
      options: [{ label: "Live Chat", value: "true" }],
    },
    {
      key: "supportEngaged",
      options: [{ label: "Support Engaged", value: "true" }],
    },
    {
      key: "supportRelated",
      options: [{ label: "Support Related", value: "true" }],
    },
  ],
};

export const reportfilter = {
  searchPlaceholder: "Search",
  filters: [
    {
      category: "With Report Name",
      key: "reportname",
      options: [
        { label: "Regular Orders", value: "regularOrders" },
        { label: "Special Orders", value: "specialOrder" },
      ],
    },
    {
      category: "With Order Status",
      key: "orderStatus",
      options: [
        { label: "Booked", value: "Booked" },
        { label: "Processing", value: "Processing" },
        { label: "Delivered", value: "Delivered" },
      ],
    },
    {
      category: "With Position",
      key: "position",
      options: [
        { label: "Orders with No Holds", value: "No Holds" },
        { label: "Orders Under Review", value: "Review" },
        { label: "Orders Rejected", value: "Reject" },
      ],
    },
  ],
};
