// LOCAL
// export const API_URL = "http://localhost:9000/api/";
// export const BASE_URL = "http://localhost:9000/";

// export const API_URL = "https://fe02-2405-201-6800-d089-9f5-ade5-9db5-62e9.ngrok-free.app/api/";
// export const BASE_URL = "https://fe02-2405-201-6800-d089-9f5-ade5-9db5-62e9.ngrok-free.app";

//  QA
// export const API_URL = "https://apiqa.vpickup.com/api/";
// export const BASE_URL = "https://apiqa.vpickup.com/";

//  DEVELOPMENT
export const API_URL = "https://apiqa.vpickup.com/api/";
export const BASE_URL = "https://apiqa.vpickup.com/";



