import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { ControlPoint, AccessTimeFilled } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";
import { usePagination } from "../../hooks";
import DynamicTable from "../../components/DynamicTable";
import {
  orderFilters,
  regularOfferFilters,
  tutorialFilters,
} from "../../utils/filterConfig";
import {
  Visibility,
  VisibilityOff,
  EditNote,
  Delete,
} from "@mui/icons-material";

export function Tutorials() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openRegularOfferModal, setOpenRegularOfferModal] = useState(false);
  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    from: "",
    to: "",
    search: "",
    visibility: "",
    videoSourceType: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/tutorials", {
      limit,
      page,
      search: filtrationData.search,
      from: filtrationData.from,
      to: filtrationData.to,
      visibility: filtrationData.visibility,
      videoSourceType: filtrationData.videoSourceType,
    });
    return data;
  }
  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    setFiltrationData((prevData) => ({
      ...prevData,
      search: value,
    }));
  };

  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    {
      id: "tutorialId",
      label: "Tutorial ID",
      align: "center",
    },

    {
      id: "title",
      label: "Video Title",
      align: "center",
    },
    {
      id: "createdAt",
      label: "Created Date",
      align: "center",
    },
    {
      id: "videoSourceType",
      label: "Video Type",
      align: "center",
    },
    {
      id: "tags",
      label: "Tags",
      align: "center",
    },
    {
      id: "duration",
      label: "Duration",
      align: "center",
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginLeft: "20px",
              gap: 1,
            }}
          >
            <AccessTimeFilled sx={{ color: "#000", fontSize: "16px" }} />
            <Typography sx={{ fontSize: "12px" }}>{row.duration}</Typography>
          </Box>
        );
      },
    },
    {
      id: "visibility",
      label: "Visibility",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography sx={{ fontSize: "12px" }}>
            {JSON.parse(row.visibility).map((v, index, array) =>
              index === array.length - 1 ? v : v + ", "
            )}
          </Typography>
        );
      },
    },
    {
      id: "action",
      label: "View",
      renderCell: (row) => {
        const isRowExpanded = expandedRows.includes(row._id);
        return (
          <>
            {isRowExpanded ? (
              <IconButton
                sx={{
                  fontSize: "8px",
                  color: "rgba(104, 60, 140, 1)",
                }}
                onClick={() => handleExpandRow(row._id)}
                // Add your onClick logic for delete here
              >
                <VisibilityOff />
              </IconButton>
            ) : (
              <IconButton
                sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
                onClick={() => handleExpandRow(row._id)}
              >
                <Visibility />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  const handleExpandRow = (rowId) => {
    setExpandedRows((prev) =>
      prev.includes(rowId)
        ? prev.filter((id) => id !== rowId)
        : [...prev, rowId]
    );
  };

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              color: "#673C8E", // Return the value directly
            }}
          >
            Tutorials
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: "right", paddingRight: "24px" }}
        >
          <Button
            onClick={() => navigate("add")}
            variant="contained"
            size="small"
            sx={{
              padding: "10px 20px",
              textTransform: "capitalize",
              backgroundColor: "rgba(103, 60, 142, 1)",
              color: "#fff",
              borderRadius: "4px",
              fontWeight: 600,
              // fontSize: "16px",
              "&:hover": {
                backgroundColor: "rgba(103, 60, 142, 1)",
                color: "#fff",
              },
            }}
            startIcon={<ControlPoint />}
          >
            Create New Video
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ paddingRight: "24px" }}>
        <DynamicTable
          rows={rows}
          type="tutorials"
          url="tutorials"
          setRows={setRows}
          searchPlaceholderData={searchInput}
          onSearchData={handleSearchInputChange}
          filterConfig={tutorialFilters} // Pass the filter checkbox options
          noRecordTitle="No tutorials yet !" // no data title
          onFilterData={getFilteredData} // filteration data
          onResetFilters={() => {
            setFiltrationData((prev) => ({
              search: prev.search,
              from: "",
              to: "",
              visibility: "",
              videoSourceType: "",
            }));
          }}
          paginationProps={{ limit, page, setLimit, setPage, total }}
          columns={clientColumns} // Pass the columns prop
          expandedRows={expandedRows}
          handleExpandRow={handleExpandRow}
        />
      </Box>
    </>
  );
}
