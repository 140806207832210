import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import { StarOutlined, Visibility, StarBorder } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import OrderPageCard from "../../components/cards/OrderPageCard";
import DynamicTable from "../../components/DynamicTable";
import { usePagination } from "../../hooks";
import { $crud } from "../../utils/CrudFactory";
import { orderFilters } from "../../utils/filterConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PositionModal from "../../components/modals/OrderPositionModal";
import dayjs from "dayjs";

export function Orders() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [newPosition, setNewPosition] = useState(""); // state to open position modal
  const [positionModalOpen, setPositionModalOpen] = useState(false); // state to open position modal
  const [positionPlaceholder, setPositionPlaceholder] = useState(""); // state to open position modal
  const [positionBtn, setPositionBtn] = useState(""); // state to open position modal
  const [positionModalTitle, setPositionModalTitle] = useState(""); //state to display position modal title
  const [selectedRow, setSelectedRow] = useState(null); // To store the selected row for which position is being updated
  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS
  const [searchInput, setSearchInput] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const driverId = queryParams.get("driverId");
  const clientId = queryParams.get("clientId");
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    orderType: "",
    orderStatus: "",
    position: "",
    search: driverId || clientId || "",
    from: "",
    to: "",
    amountFrom: "",
    amountTo: "",
  });

  useEffect(() => {
    if (driverId) {
      setSearchInput(driverId);
    }
    if (clientId) {
      setSearchInput(clientId);
    }
  }, [driverId, clientId]);
  // STATE TO MANAGE FILTERS --------------------------------------
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/orders", {
      limit,
      page,
      search: filtrationData.search,
      position: filtrationData.position,
      orderType: filtrationData.orderType,
      orderStatus: filtrationData.orderStatus,
      from: filtrationData.from,
      to: filtrationData.to,
      amountFrom: filtrationData.amountFrom,
      amountTo: filtrationData.amountTo,
    });
    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  // function to add data in position modal-----------------------------
  const handlePositionChange = (row, newPosition) => {
    setSelectedRow(row);
    // console.log(newPosition, "=============================");
    setNewPosition(newPosition);
    switch (newPosition) {
      case "Reject":
        setPositionModalTitle("Reason For Rejection");
        setPositionPlaceholder("Enter Reason For Rejection here");
        setPositionBtn("Reject");
        break;
      case "Review":
        setPositionModalTitle("Reason For Review");
        setPositionPlaceholder("Enter Reason For Review here");
        setPositionBtn("Review");
        break;
      case "No Holds":
        setPositionModalTitle("Reject Resolution Comments");
        setPositionPlaceholder("Enter Comments For Reject Resolution");
        setPositionBtn("No Holds");
        break;
      default:
        setPositionModalTitle("");
        break;
    }
    setPositionModalOpen(true);
  };

  // function to close position modal---------------------------------
  const handleCloseModal = () => {
    setPositionModalTitle(false);
    setSelectedRow(null);
    setPositionModalOpen("");
  };

  // function to call the api and update the comment -------------------------------------
  const handlePositionSubmit = async (reason) => {
    // Logic to update position with reason
    console.log(
      `Updating position for order ${selectedRow._id} with reason: ${reason}`
    );
    const { data, type } = await $crud.put(`admin/order`, {
      position: newPosition,
      comment: reason,
      _id: selectedRow._id,
    });
    console.log(data);
    if (type == "success") {
      retrieveData().then((res) => {
        setRows(res.result);
        setTotal(res.total);
      });
    }
    handleCloseModal();
  };

  // FUNCTION TO GET THE ACTIVE STATUS OF POSITIONA ND CURRENT STATUS COLUMNS ------------------
  const getActiveStatus = (statuses) => {
    return statuses.find((status) => status.active)?.status || "";
  };
  // FUNCTION TO GET THE ACTIVE STATUS OF POSITIONA ND CURRENT STATUS COLUMNS ------------------

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    setFiltrationData((prevData) => ({
      ...prevData,
      search: value,
    }));
  };

  const statusOptions = ["Booked", "Processing", "Delivered"];
  const positionOptions = ["Reject", "No Holds", "Review"];
  // COLUMNS IN THE ORDER TABLE-----------------------------
  const orderColumns = [
    {
      id: "specialOrder",
      label: "Special",
      align: "center",
      renderCell: (row) => {
        return (
          <>
            {row.specialOrder ? (
              <StarOutlined
                // onClick={() =>
                //   handleSpecialOrderUpdate(row.orderID, row.specialOrder)
                // }
                sx={{ color: "#F7C604", cursor: "auto" }}
              />
            ) : (
              <StarBorder
                // onClick={() =>
                //   handleSpecialOrderUpdate(row.orderID, row.specialOrder)
                // }
                sx={{ color: "#F7C604", cursor: "auto" }}
              />
            )}
          </>
        );
      },
    },
    {
      id: "orderId",
      label: "Order ID",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{ fontSize: "12px", color: theme.palette.text.secondary }}
          >
            {row.orderId}
          </Typography>
        );
      },
    },
    {
      id: "dateBooked",
      label: "Date Booked",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{ fontSize: "12px", color: theme.palette.text.secondary }}
          >
            {row.dateBooked}
          </Typography>
        );
      },
    },
    {
      id: "waiting",
      label: "Waiting",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{ fontSize: "12px", color: theme.palette.text.secondary }}
          >
            {row.waiting}
          </Typography>
        );
      },
    },
    { id: "bLocation", label: "B Location", align: "center" },
    { id: "customerId", label: "CID", align: "center" },
    { id: "tpu", label: "TPU/s", align: "center" },
    { id: "ppu", label: "PPU/s", align: "center" },
    {
      id: "amount",
      label: "Amount",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{ fontSize: "12px", color: theme.palette.text.secondary }}
          >
            ${row.amount.toFixed(2)}
          </Typography>
        );
      },
    },
    {
      id: "currentStatus",
      label: "Current Status",
      align: "center",
      renderCell: (row) => {
        const activeStatus = row.currentStatus.status;
        const getStatusStyles = (status) => {
          switch (status) {
            case "Booked":
              return {
                background: "rgba(103, 60, 142, 0.35)",
                color: "rgba(103, 60, 142, 1)",
                fontSize: "10px",
              };
            case "Processing":
              return {
                background: "rgba(213, 229, 244, 1)",
                color: "rgba(76, 170, 224, 1)",
                fontSize: "10px",
              };
            case "Delivered":
              return {
                background: "rgba(217, 243, 234, 1)",
                color: "rgba(42, 189, 139, 1)",
                fontSize: "10px",
              };
            default:
              return {
                background: "#FFF0ED",
                color: "rgba(254, 133, 106, 1)",
              };
          }
        };
        const styles = getStatusStyles(activeStatus);

        return (
          <Button
            sx={{
              borderColor: styles.background,
              background: styles.background,
              color: styles.color,
              padding: "8px 18px",
              textTransform: "capitalize",
              cursor: "auto",
              border: "none",
              fontSize: "10px",
              width: "90px",
              outline: "none !important",
              "&:hover": {
                outline: styles.background,
                background: styles.background,
              },
              "&:focus": {
                outline: styles.background,
                background: styles.background,
              },
            }}
          >
            {activeStatus}
          </Button>
        );
      },
    },
    {
      id: "position",
      label: "Position",
      align: "center",
      renderCell: (row) => {
        const activePosition = row.position.status;
        const getPositionStyles = (status) => {
          switch (status) {
            case "Reject":
              return {
                background: "rgba(255, 240, 237, 1)",
                color: "rgba(254, 133, 106, 1)",
                fontSize: "12px",
              };
            case "Review":
              return {
                background: "rgba(247, 198, 4, 0.2)",
                color: "rgba(247, 198, 4, 1)",
                fontSize: "12px",
              };
            case "No Holds":
              return {
                background: "rgba(217, 243, 234, 1)",
                color: "rgba(42, 189, 139, 1)",
                fontSize: "12px",
              };
            default:
              return {
                background: "#FFF0ED",
                color: "rgba(254, 133, 106, 1)",
              };
          }
        };
        const styles = getPositionStyles(activePosition);

        return (
          <>
            <Select
              value={activePosition}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                borderColor: styles.background,
                background: styles.background,
                color: styles.color,
                border: "none",
                fontSize: "12px",
                outline: "none !important",
                "& .MuiSelect-icon": {
                  color: styles.color,
                },
                "&:hover": {
                  outline: styles.background,
                },
                "&:focus": {
                  outline: styles.background,
                },
                "& .MuiSelect-select": {
                  paddingRight: "28px !important",
                  padding: "8px",
                  width: "60px",
                },
              }}
              onChange={(e) => handlePositionChange(row, e.target.value)}
            >
              {positionOptions.map((position) => (
                <MenuItem
                  key={position}
                  value={position}
                  sx={getPositionStyles(position)}
                >
                  {position}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      },
    },
    // { id: "positionComment", label: "Reason", align: "center" },
    {
      id: "action",
      label: "View",
      renderCell: (row) => {
        return (
          <IconButton
            sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
            onClick={() =>
              navigate(`/order-detail`, { state: { selectedOrder: row } })
            }
          >
            <Visibility />
          </IconButton>
        );
      },
    },
  ];

  // COLUMNS IN THE ORDER TABLE-----------------------------

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    if (!data.orderType) data.orderType = "";
    if (!data.position) data.position = "";
    if (!data.search) data.search = "";
    if (!data.orderStatus) data.orderStatus = "";
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
    setPage(0);
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------

  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              // color: "#673C8E",
              color: theme.palette.text.primary,
            }}
          >
            Orders
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="end"
          gap={2}
          alignItems="center"
        >
          <Box
            sx={{
              width: "38%",
              paddingLeft: "6px",
              display: "flex",
              borderRadius: "4px",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              // border: "2px solid rgba(103, 60, 142, 1)",
            }}
          >
            <Typography
              sx={{ color: "#673C8E", fontSize: "16px", fontWeight: 400 }}
            >
              From
            </Typography>
            <LocalizationProvider
              sx={{
                "& .PrivatePickersPopperRoot-popupIndicator": {
                  display: "none",
                },
              }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                value={filtrationData?.from ? dayjs(filtrationData.from) : null}
                onChange={(newValue) => {
                  const formattedDate = dayjs(newValue).toISOString();
                  setFiltrationData({
                    ...filtrationData,
                    from: formattedDate,
                  });
                  // handleFilterChange("from", formattedDate);
                }}
                PopoverProps={{ disableEnforceFocus: true }} // Disable enforcing focus
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "7px",
                    color: "#673C8E",
                    borderRadius: "14px",
                    border: "none", // Remove the border from the input
                  },
                  "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                    color: "#673C8E", // Change the color of the icon button
                  },
                  "& .MuiOutlinedInput-root": {
                    // borderRadius: "14px",
                  },
                  "& .MuiOutlinedInput-root fieldset": {
                    // border: "none",
                    border: "2px solid rgba(103, 60, 142, 1)",
                  },
                  "& .MuiOutlinedInput-root fieldset:hover": {
                    borderColor: "#673C8E", // Change border color on hover
                  },
                  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                    borderColor: "#673C8E", // Change border color when focused
                  },
                }}
                renderInput={(params) => (
                  <TextField placeholder="Select Date" {...params} />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              width: "35%",
              paddingLeft: "6px",
              display: "flex",
              borderRadius: "4px",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              // border: "2px solid rgba(103, 60, 142, 1)",
            }}
          >
            <Typography
              sx={{ color: "#673C8E", fontSize: "16px", fontWeight: 400 }}
            >
              To
            </Typography>
            <LocalizationProvider
              sx={{
                "& .PrivatePickersPopperRoot-popupIndicator": {
                  display: "none",
                },
              }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                value={filtrationData?.to ? dayjs(filtrationData.to) : null}
                onChange={(newValue) => {
                  const formattedDate = dayjs(newValue).toISOString();
                  setFiltrationData({
                    ...filtrationData,
                    to: formattedDate,
                  });
                  // handleFilterChange("from", formattedDate);
                }}
                PopoverProps={{ disableEnforceFocus: true }} // Disable enforcing focus
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "7px",
                    color: "#673C8E",
                    borderRadius: "14px",
                    border: "none", // Remove the border from the input
                  },
                  "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                    color: "#673C8E", // Change the color of the icon button
                  },
                  "& .MuiOutlinedInput-root": {
                    // borderRadius: "14px",
                  },
                  "& .MuiOutlinedInput-root fieldset": {
                    // border: "none",
                    border: "2px solid rgba(103, 60, 142, 1)",
                  },
                  "& .MuiOutlinedInput-root fieldset:hover": {
                    borderColor: "#673C8E", // Change border color on hover
                  },
                  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                    borderColor: "#673C8E", // Change border color when focused
                  },
                }}
                renderInput={(params) => (
                  <TextField placeholder="Select Date" {...params} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "25px", display: "none" }}>
          {/* =============================  ORDER CARDS==================================== */}
          <Grid container spacing={2}>
            <OrderPageCard
              title="Total Orders"
              count={rows.length > 0 ? "0" : 0}
              rate={rows.length > 0 ? 100 : 0}
            />
            <OrderPageCard
              title="New Orders"
              count={rows.length > 0 ? "0" : 0}
              rate={rows.length > 0 ? 70 : 0}
            />
            <OrderPageCard
              title="Refunds"
              count={rows.length > 0 ? "0" : 0}
              rate={rows.length > 0 ? 10 : 0}
            />
          </Grid>
          {/* =============================  ORDER CARDS==================================== */}
        </Grid>
      </Grid>

      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE ORDER LIST IN TABLE ===========================*/}
      <DynamicTable
        // searchPlaceholderData={driverId || ""}
        searchPlaceholderData={searchInput}
        onSearchData={handleSearchInputChange}
        rows={rows}
        type="orders"
        url="orders"
        setRows={setRows}
        filterConfig={orderFilters} // Pass the filter checkbox options
        noRecordTitle="No orders to display" // no data title
        onFilterData={getFilteredData} // filteration data
        onResetFilters={() => {
          setFiltrationData((prev) => ({
            search: prev.search,
            orderType: "",
            orderStatus: "",
            position: "",
            from: "",
            to: "",
            amountTo: "",
            amountFrom: "",
          }));
        }}
        // onSearchData={(value) =>
        //   setFiltrationData({ ...filtrationData, search: value })
        // }
        paginationProps={{ limit, page, setLimit, setPage, total }}
        columns={orderColumns} // Pass the columns prop
      />
      {/*========================= CALLING DYNAMIC TABLE COMPONENT TO SHOW THE ORDER LIST IN TABLE ===========================*/}

      {positionModalOpen && selectedRow && (
        <PositionModal
          ifOrder={true}
          open={positionModalOpen}
          title={positionModalTitle}
          onSubmit={handlePositionSubmit}
          onClose={handleCloseModal}
          btnText={positionBtn}
          placeholder={positionPlaceholder}
        />
      )}
    </>
  );
}

// export default Orders;
