import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  CameraAltOutlined,
  Edit,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import avatar from "../../assets/images/svg/avatar.svg";
import pinIcon from "../../assets/images/svg/pinIcon.svg";
import joinIcon from "../../assets/images/joinIcon.svg";
import { useNavigate } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";
import { styled } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import { BASE_URL } from "../../constants";
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
export function AccountSettings() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState(
    user.address || "45 New Avenue Toronto, ON - L5N 1B2, Canada."
  );
  const [avatar, setAvatar] = useState(null); // Stores the avatar URL or empty if none
  const [uploadedFile, setUploadedFile] = useState(null); // To store the uploaded file
  console.log(isEditing, "=========================isediting");

  // Handle the file change (when user selects an image)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 1024 * 1024; // 1 MB in bytes

    if (file && file.size > maxSize) {
      enqueueSnackbar("Image size should not exceed 1 MB.", {
        variant: "error",
      });
      setUploadedFile(null); // Clear selected file
      return;
    }

    // If the file is valid, set it for preview
    setUploadedFile(file);
    setIsEditing(true);
    setAvatar(URL.createObjectURL(file));
    // Optionally, you can generate a preview for the uploaded image
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   setAvatar(reader.result); // Update avatar preview
    // };
    // reader.readAsDataURL(file); // Read the image as a base64 string
  };
  const handleCancel = () => {
    setAvatar(null);
    setUploadedFile(null);
    setAddress(user.address || "45 New Avenue Toronto, ON - L5N 1B2, Canada.");
    setIsEditing(false); // Reset edit mode
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setIsEditing(true); // Enable edit mode
  };

  console.log(uploadedFile, "profile -------------------");
  console.log(address, "address -------------------");

  const handleUpdateProfile = async () => {
    const formData = new FormData();
    formData.append("address", address);
    formData.append("_id", user._id);
    if (uploadedFile) {
      formData.append("profile", uploadedFile);
    }
    const { data, type } = await $crud.put(`admin/profile`, formData);
    if (type == "success") {
      const updatedUser = data.user;
      localStorage.setItem("user", JSON.stringify(updatedUser));
      window.location.reload();
    }
  };
  const [mfaScreen, setMfaScreen] = useState(false);
  const theme = useTheme();
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();
  const handleChangePassword = async () => {
    $crud
      .post("admin/password-change-send-code", {
        id: user._id,
      })
      .then((response) => {
        const { message, type, data } = response;
        if (type == "success") {
          localStorage.clear();
          navigate("/forgot-password", {
            state: {
              changeAccPassword: true,
              adminId: user._id,
              adminEmail: user.email,
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error sending code:", error);
      });
  };

  const handleMFAChange = async () => {
    $crud
      .post("admin/mfa-change-verify", {
        id: user._id,
        password,
      })
      .then((response) => {
        const { message, type, data } = response;
        if (type == "success") {
          localStorage.clear();
          navigate("/authenticator", {
            state: {
              qrCodeDataURL: data.qrCodeDataURL,
              otpSecret: data.otpSecret,
              userId: data.loginUser._id,
              token: `Bearer ${data.token}`,
              user: JSON.stringify(data?.loginUser),
              changeMFA: true,
              isQRScanned: data?.isQRScanned,
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error sending code:", error);
      });
  };

  return (
    <Grid container sx={{ padding: "0 18px" }}>
      {mfaScreen ? (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E",
                textAlign: "center",
                marginTop: "86px",
              }}
            >
              Edit your account information:
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Card
              sx={{
                padding: "40px",
                margin: "0 auto",
                width: "650px",
                background: theme.palette.primary.main,
                boxShadow: theme.palette.boxShadow.primary,
                borderRadius: 0,
                border: theme.palette.border.primary,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "30px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Re-register 2FA
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "20px",
                  fontWeight: 600,
                  marginTop: "30px",
                  mb: 1,
                }}
              >
                Verify Password
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  placeholder="Enter Password"
                  sx={{
                    color: "#1C1B1F",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C1B1F", // Change outlined border color when focused
                    },
                    "& .MuiOutlinedInput-notchedOutline legend": {
                      display: "none", // Remove the notch
                    },
                    // Hide the arrows (spinners)
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                      "&::-webkit-outer-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                      "&::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    },
                  }}
                  size="small"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  // onBlur={handleBlur}
                  type="text"
                  className={!showPassword ? "hidden-otp" : ""}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  marginTop: "20px",
                }}
              >
                <Button
                  onClick={handleMFAChange}
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    borderColor: "#673C8E",
                    background: "#673C8E",
                    color: "#fff",
                    padding: "10px 30px",
                    fontSize: "14px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "#673C8E",
                      borderColor: "#673C8E",
                      color: "#fff",
                    },
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Card>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Account Settings
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginRight: "24px" }}>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                height: "237px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "122px",
                  background: theme.palette.text.primary,
                  position: "absolute",
                  top: 0,
                  zIndex: 1, // Ensure the button section appears above other elements
                }}
              ></Box>

              <Box
                sx={{
                  position: "absolute",
                  paddingBottom: "20px",
                  width: "100%",
                  bottom: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  zIndex: 1, // Keep this section below the button
                  borderBottom: theme.palette.border.primary,
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    position: "absolute",
                    top: "12px",
                    right: "30px",
                    borderRadius: "10px",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                    fontWeight: 500,
                    zIndex: 2,
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                    display: isEditing ? "block" : "none",
                  }}
                  onClick={handleUpdateProfile}
                >
                  Update Profile
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    position: "absolute",
                    top: "12px",
                    display: isEditing ? "block" : "none",
                    right: "140px",
                    borderRadius: "10px",
                    color: "#fff",
                    fontWeight: 500,
                    textTransform: "capitalize",
                    mr: 2,
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <div>
                  {/* Avatar with Edit Icon inside Badge */}
                  <Badge
                    sx={{ cursor: "pointer" }}
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={
                      <IconButton
                        sx={{
                          width: 24,
                          height: 24,
                          backgroundColor: "#fff", // Optional: change background color of the icon
                          borderRadius: "50%", // To make it circular
                          "&:hover": {
                            backgroundColor: "#fff",
                          },
                        }}
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        } // Trigger file input click
                      >
                        <Edit sx={{ fontSize: 16 }} />
                      </IconButton>
                    }
                  >
                    <Avatar
                      sx={{
                        width: "100px",
                        height: "100px",
                        bgcolor: avatar
                          ? "transparent"
                          : "rgba(221, 202, 238, 0.7)", // Background for initials
                        color: avatar ? "transparent" : "#683c8e", // Color for initials text
                      }}
                      alt="User Avatar"
                      src={
                        avatar || `${BASE_URL}/adminProfiles/${user.profile}`
                      } // Show uploaded image or fallback to profile image
                    >
                      {/* Fallback to initials if no avatar */}
                      {!avatar && user.name ? user.name.charAt(0) : null}
                    </Avatar>
                  </Badge>

                  {/* Hidden file input field to trigger file selection */}
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "18px",
                    color: theme.palette.text.black,
                  }}
                >
                  {user.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 4,
                    mt: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <img width="20" src={pinIcon} />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      Canada
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <img width="20" src={joinIcon} />

                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      Joined May 27
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginTop: "20px",
              borderBottom: theme.palette.border.primary,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: theme.palette.text.primary, // Return the value directly
              }}
            >
              Account information:
            </Typography>
            <Grid container mt={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    color: theme.palette.text.primary,
                    mb: 1,
                  }}
                >
                  Name
                </Typography>
                <TextField
                  sx={{
                    color: "#1C1B1F",
                    width: "60%",
                    mb: 2,
                    "& .MuiInputLabel-root": {
                      color: "#1C1B1F !important", // Adding !important to ensure the color overrides
                    },
                  }}
                  size="small"
                  fullWidth
                  value={user.name}
                  variant="outlined"
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                  disabled
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    color: theme.palette.text.primary,
                    mb: 1,
                  }}
                >
                  Address
                </Typography>
                <TextField
                  multiline
                  sx={{
                    color: "#1C1B1F",
                    width: "60%",
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: theme.palette.text.secondary,
                      },
                      "&.Mui-focused fieldset": { borderColor: "#1C1B1F" },
                    },
                  }}
                  size="small"
                  fullWidth
                  value={address}
                  onChange={handleAddressChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                      // display: "flex",
                      paddingRight: "24px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        fontSize: "20px",
                        mb: 1,
                        color: theme.palette.text.primary,
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      sx={{
                        color: "#1C1B1F",
                        mb: 2,
                        ml: "auto",
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1C1B1F",
                          },
                        },
                      }}
                      size="small"
                      fullWidth
                      value={user.phone || " +1 123456789"}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "80%",
                      // display: "flex",
                      flexDirection: "column",
                      paddingRight: "24px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        fontSize: "20px",
                        mb: 1,
                        color: theme.palette.text.primary,
                      }}
                    >
                      Member ID | Joined Since
                    </Typography>
                    <TextField
                      sx={{
                        color: "#1C1B1F",
                        mb: 2,
                        ml: "auto",
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important",
                        },
                      }}
                      size="small"
                      fullWidth
                      value={user.memberId || "EID123456 | 24/05/24"}
                      variant="outlined"
                      disabled
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "20px", mb: 3 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: theme.palette.text.primary, // Return the value directly
              }}
            >
              Edit Your Account information:
            </Typography>
            <Grid container mt={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    color: theme.palette.text.primary,
                    mb: 1,
                  }}
                >
                  2FA
                </Typography>
                <Button
                  onClick={() => setMfaScreen(true)}
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: theme.palette.text.primary,
                    color: theme.palette.primary.main,
                    textTransform: "capitalize",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: theme.palette.text.primary,
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  Re-Register 2FA
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      width: "27%",
                      // display: "flex",
                      paddingRight: "24px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        fontSize: "20px",
                        mb: 1,
                        color: theme.palette.text.primary,
                      }}
                    >
                      Password
                    </Typography>
                    <Button
                      onClick={handleChangePassword}
                      variant="contained"
                      size="small"
                      sx={{
                        borderRadius: "4px",
                        backgroundColor: theme.palette.text.primary,
                        color: theme.palette.primary.main,
                        textTransform: "capitalize",
                        fontWeight: 500,
                        "&:hover": {
                          backgroundColor: theme.palette.text.primary,
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Change Password
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
