import React, { useState } from "react";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TutorialVideoBox = ({ row, BASE_URL, playIcon }) => {
  const [openDialog, setOpenDialog] = useState(false);

  // Function to handle click based on video source type
  const handleVideoClick = () => {
    if (row.videoSourceType === "Link") {
      // Open video link in a new tab
      window.open(row.video, "_blank");
    } else if (row.videoSourceType === "Embedded") {
      // Open dialog for embedded video
      setOpenDialog(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "260px",
          height: "120px",
          background: "#673C8E",
          mb: 1,
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Display the thumbnail image */}
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          src={`${BASE_URL}tutorials/${row.thumbnail}`}
          alt="thumbnail"
        />

        {/* Overlay Play Icon */}
        <Box
          onClick={handleVideoClick}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
        >
          <img src={playIcon} alt="Play" width="40px" height="40px" />
        </Box>
      </Box>

      {/* Dialog for embedded video */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {/* Close button for dialog */}
          <IconButton
            onClick={() => setOpenDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Display embedded video iframe */}
          <Box sx={{ mt: 2 }}>
            <div
              dangerouslySetInnerHTML={{ __html: row.video }}
              style={{ width: "100%", height: "100%", overflow: "hidden" }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TutorialVideoBox;
