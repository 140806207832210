import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { ControlPoint, EditNote, EditSharp } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";
import { usePagination } from "../../hooks";
import DynamicTable from "../../components/DynamicTable";
import { acceptedCategoryFilters } from "../../utils/filterConfig";
import AddCategoryModal from "../../components/modals/AddCategoryModal";
import editimg from "../../assets/images/svg/editblack.svg";

export function AcceptedCategories() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS

  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    search: "",
    from: "",
    to: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------
  async function retrieveData() {
    const isFilterApplied = Object.values(filtrationData).some(
      (value) => value !== "" && value !== false
    );

    const params = {
      search: filtrationData.search,
      from: filtrationData.from,
      to: filtrationData.to,
    };

    // Conditionally include limit and page if no filters are applied
    if (!isFilterApplied) {
      params.limit = limit;
      params.page = page;
    }

    const { data } = await $crud.retrieve("admin/accepted-category", params);
    return data;
  }
  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    if (openAddCategoryModal) return;
    retrieveData().then((res) => {
      setRows(res.result);
      setTotal(res.total);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData, openAddCategoryModal]);

  const handleSearchInputChange = (value) => {
    if (value.trim() === "") {
      setFiltrationData((prevData) => ({
        ...prevData,
        search: "",
      }));
      setSearchInput("");
    }
    setSearchInput(value);
    setFiltrationData((prevData) => ({
      ...prevData,
      search: value,
    }));
  };

  const handleCloseModal = () => {
    setOpenAddCategoryModal(false);
    navigate("/others/accepted-categories");
  };

  const handleAddCategory = async (name) => {
    // Logic to update position with reason
    const { data, type } = await $crud.post(`admin/accepted-category`, {
      name,
    });
    if (type == "success") {
      // navigate("/services/tutorials");
    }
    handleCloseModal();
  };

  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    {
      id: "name",
      label: "Category Name",
      align: "center",
    },
    {
      id: "createdAt",
      label: "Created Date",
      align: "center",
    },
    {
      id: "assignedOrders",
      label: "Assigned Orders",
      align: "center",
    },
    //   {
    //     id: "status",
    //     label: "Status",
    //     align: "center",
    //   },
    {
      id: "status",
      label: "Status",
      align: "center",
      renderCell: (row) => {
        const getStatusStyles = (status) => {
          switch (status) {
            case "Active":
              return {
                background: "rgb(37, 137, 0)",
              };
            case "Disabled":
              return {
                background: "rgb(193, 0, 0)",
              };
            default:
              return {
                background: "#FFF0ED",
              };
          }
        };
        const styles = getStatusStyles(row.status);
        return (
          <Button
            sx={{
              background: styles.background,
              cursor: "auto",
              borderRadius: "22px",
              color: "white",
              padding: "7px 27px",
              textTransform: "capitalize",
              border: "none",
              fontSize: "12px",
              width: "100px",
              outline: "none !important",
              "&:hover": {
                outline: styles.background,
                background: styles.background,
              },
              "&:focus": {
                outline: styles.background,
                background: styles.background,
              },
            }}
          >
            {row.status}
          </Button>
        );
      },
    },
    //   {
    //     id: "visibility",
    //     label: "Visibility",
    //     align: "center",
    //     renderCell: (row) => {
    //       return (
    //         <Typography sx={{ fontSize: "12px" }}>
    //           {row.visibility.includes("Drivers") &&
    //           row.visibility.includes("Clients")
    //             ? row.visibility.includes("Drivers") &&
    //               row.visibility.startsWith("Drivers")
    //               ? "Drivers & Clients"
    //               : "Clients & Drivers"
    //             : row.visibility}
    //         </Typography>
    //       );
    //     },
    //   },
    {
      id: "action",
      label: "Edit",
      align: "center",
      renderCell: (row) => {
        const isRowExpanded = expandedRows.includes(row._id);
        return (
          <IconButton
            sx={{
              fontSize: "8px",
              color: "rgba(104, 60, 140, 1)",
            }}
            onClick={() => navigate(`edit`, { state: row })}
            // Add your onClick logic for delete here
          >
            <img src={editimg} alt="" />
          </IconButton>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  const handleExpandRow = (rowId) => {
    setExpandedRows((prev) =>
      prev.includes(rowId)
        ? prev.filter((id) => id !== rowId)
        : [...prev, rowId]
    );
  };

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    if (!data.visibility) data.visibility = "";
    if (!data.videoSourceType) data.videoSourceType = "";
    if (!data.search) data.search = "";
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
    setPage(0);
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              color: "#673C8E", // Return the value directly
            }}
          >
            Accepted Categories
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: "right", paddingRight: "24px" }}
        >
          <Button
            onClick={() => {
              setOpenAddCategoryModal(true);
              //  navigate("add")
            }}
            variant="contained"
            size="small"
            sx={{
              padding: "10px 20px",
              textTransform: "capitalize",
              backgroundColor: "rgba(103, 60, 142, 1)",
              color: "#fff",
              borderRadius: "4px",
              fontWeight: 600,
              // fontSize: "16px",
              "&:hover": {
                backgroundColor: "rgba(103, 60, 142, 1)",
                color: "#fff",
              },
            }}
            startIcon={<ControlPoint />}
          >
            Add New Category
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ paddingRight: "24px" }}>
        <DynamicTable
          rows={rows}
          type="accepted-category"
          url="accepted-category"
          setRows={setRows}
          searchPlaceholderData={searchInput}
          onSearchData={handleSearchInputChange}
          filterConfig={acceptedCategoryFilters} // Pass the filter checkbox options
          noRecordTitle="No accepted categories yet !" // no data title
          onFilterData={getFilteredData} // filteration data
          onResetFilters={() => {
            setFiltrationData((prev) => ({
              search: prev.search,
              from: "",
              to: "",
            }));
          }}
          filtrationData={filtrationData}
          paginationProps={{ limit, page, setLimit, setPage, total, setTotal }}
          columns={clientColumns} // Pass the columns prop
          expandedRows={expandedRows}
          handleExpandRow={handleExpandRow}
        />
        {openAddCategoryModal && (
          <AddCategoryModal
            open={openAddCategoryModal}
            onSubmit={handleAddCategory}
            onClose={handleCloseModal}
          />
        )}
      </Box>
    </>
  );
}
