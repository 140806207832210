import { Box } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  LabelList,
  ResponsiveContainer,
  Legend,
} from "recharts";

// Sample data
const driverData = [
  { name: "Driver 1", performance: 47 },
  { name: "Driver 2", performance: 70 },
  { name: "Driver 3", performance: 95.6 },
  { name: "Driver 4", performance: 27 },
  { name: "Driver 5", performance: 7 },
  { name: "Driver 6", performance: 50 },
  { name: "Driver 7", performance: 67 },
];

// Custom Bar shape component
const CapsuleBar = (props) => {
  const { x, y, width, height, fill } = props;
  const radius = 10;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        rx={radius}
        ry={radius}
      />
    </g>
  );
};

// Custom label to add % sign
const CustomLabel = (props) => {
  const { x, y, value, width, height } = props;
  return (
    <text
      x={x}
      y={y}
      //   fill="rgba(103, 60, 142, 1)"
      //   textAnchor="middle"
      dy={-10}
    >
      {`${value}%`}
    </text>
  );
};

const DriversBarChart = () => {
  return (
    <Box width="100%" height={160}>
      <BarChart
        width={500}
        height={300}
        data={driverData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: "11px",
            fontWeight: 400,
            fill: "rgba(103, 60, 142, 1)",
          }}
        />
        <Bar
          dataKey="performance"
          fill="rgba(103, 60, 142, 1)"
          background={{ fill: "#fff" }}
          shape={<CapsuleBar />}
          barSize={30}
          style={{ border: "1px solid red" }}
        >
          <LabelList dataKey="performance" content={<CustomLabel />} />
        </Bar>
        {/* <Legend /> */}
      </BarChart>
    </Box>
  );
};

export default DriversBarChart;
