import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/images/svg/back.svg";
import link from "../../assets/images/svg/link.svg";
import code from "../../assets/images/svg/link.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { $crud } from "../../utils/CrudFactory";
import { BASE_URL } from "../../constants";
import RegularOfferModal from "../../components/modals/RegularOfferModal";
import { Close } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import TutorialArchiveModal from "../../components/modals/TutorialAchiveModal";

export function CreateTutorials() {
  const [selected, setSelected] = useState("Link/URL"); // State to track selected chip
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const location = useLocation();
  const [tutorial, setTutorial] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openRegularOfferModal, setOpenRegularOfferModal] = useState(false);

  const initialValues = {
    id: "",
    title: "",
    description: "",
    tags: "",
    videoSourceType: "Link",
    visibility: [],
    video: "",
    thumbnail: "",
    duration: "",
  };

  async function retrieveOffer() {
    const { data } = await $crud.retrieve("admin/tutorial", {
      id,
    });
    return data;
  }
  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    if (id != null) {
      retrieveOffer().then((res) => {
        console.log(res.tutorial.visibility);
        setTutorial(res.tutorial);
        setPreviewUrl(BASE_URL + "tutorials/" + res.tutorial.thumbnail);
        setValues({
          ...res.tutorial,
          visibility: res.tutorial.visibility.split(" "),
          id: res.tutorial._id,
        });
      });
    }
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [id]);
  const handleFileSelect = (event) => {
    setFileUploaded(false);
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);
    } else {
      enqueueSnackbar("Please select a valid image file!", {
        variant: "error",
      });
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      setFileUploaded(true);
      setFieldValue("thumbnail", selectedFile);
      // Generate a preview URL from the file
      const fileUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(fileUrl);
    } else {
      enqueueSnackbar("No file selected!", {
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    // Clear the selected file and preview
    setSelectedFile(null);
    setPreviewUrl(null);
    setFileUploaded(false);
  };
  const handleCheckboxChange = (event, setFieldValue, values) => {
    const { value, checked } = event.target;
    const visibility = [...values.visibility];
    if (checked) {
      visibility.push(value);
    } else {
      const index = visibility.indexOf(value);
      if (index > -1) {
        visibility.splice(index, 1);
      }
    }
    setFieldValue("visibility", visibility);
  };

  const onSubmit = async (values) => {
    console.log(values, "------------------");
  };

  const handleOpenRegularOfferModal = (row) => {
    setSelectedRow(row);
    setOpenRegularOfferModal(true);
  };

  // function to close position modal---------------------------------
  const handleCloseModal = () => {
    setOpenRegularOfferModal(false);
    setSelectedRow(null);
    navigate("/services/tutorials");
  };

  // function to call the api and update the comment -------------------------------------
  const handleOfferArchiveSubmit = async (reason) => {
    // Logic to update position with reason
    const { data, type } = await $crud.put(`admin/tutorial-archieve`, {
      id: selectedRow._id,
    });
    if (type == "success") {
      // navigate("/services/tutorials");
    }
    //handleCloseModal();
  };

  useEffect(() => {
    if (tutorial?.videoSourceType) {
      setSelected(
        tutorial.videoSourceType === "Link" ? "Link/URL" : "Embedded Code"
      );
    }
  }, [tutorial]);

  const handleChipClick = (chipValue) => {
    setFieldValue("video", "");
    setSelected(chipValue); // Set the selected chip
    if (chipValue == "Link/URL") {
      setFieldValue("videoSourceType", "Link");
    } else {
      setFieldValue("videoSourceType", "Embedded");
    }
  };

  const handleOfferSubmit = async () => {
    console.log(values, "values===============");
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("tags", values.tags);
    formData.append("videoSourceType", values.videoSourceType);
    formData.append("visibility", JSON.stringify(values.visibility));
    formData.append("video", values.video);
    formData.append("duration", values.duration);

    if (tutorial != []) {
      formData.append("id", values.id);
    }

    if (selectedFile) {
      formData.append("thumbnail", values.thumbnail);
    }

    try {
      const { data } = id
        ? await $crud.put(`admin/tutorial`, formData)
        : await $crud.post(`admin/tutorial`, formData);

      if (data) {
        // Navigate after success
        navigate("/services/tutorials");
      }
    } catch (err) {
      // No need for error handling here, as it’s already handled by $crud.send
      console.log("Tutorial submission failed: ", err.message);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format.").required("Required!"),
    password: Yup.string().required("Required!"),
  });
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const handleBackClick = () => {
    navigate(-1);
  };
  // console.log(values);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container sx={{ padding: "0 18px", position: "relative" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: 1,
            }}
          >
            <IconButton onClick={handleBackClick} sx={{ marginLeft: "-10px" }}>
              <img src={back} />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Tutorials
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Box
            sx={{
              width: "100%",
              marginTop: "16px",
              background: "#673C8E",
              padding: "20px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Add Details
            </Typography>
          </Box>
          <Box sx={{ width: "100%", marginTop: "25px", marginBottom: "50px" }}>
            <Box
              sx={{
                padding: "10px 30px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  marginBottom: "2px",
                }}
              >
                Video Title<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="title"
                placeholder="Enter Title"
                sx={{
                  color: "#1C1B1F",
                  mb: 3,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                size="small"
                fullWidth
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={touched.title && !!errors.title}
              />

              <Typography
                sx={{
                  fontSize: "16px",
                  marginBottom: "2px",
                }}
              >
                Description (20 to 150 characters)
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="description"
                multiline={true}
                minRows={4}
                sx={{
                  color: "#1C1B1F",
                  mb: 3,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                size="small"
                fullWidth
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />

              <Typography
                sx={{
                  fontSize: "16px",
                  marginBottom: "2px",
                }}
              >
                Tags<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="tags"
                placeholder="Enter Tags"
                sx={{
                  color: "#1C1B1F",
                  mb: 3,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                size="small"
                fullWidth
                value={values.tags}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />

              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "16px",
                    marginBottom: "10px",
                  }}
                >
                  Video Source Type<span style={{ color: "red" }}>*</span>
                </Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  {" "}
                  {/* Flex layout */}
                  <Chip
                    label="Link/URL"
                    onClick={() => handleChipClick("Link/URL")}
                    sx={{
                      backgroundColor:
                        selected === "Link/URL" ? "#673C8E" : "#fff",
                      color: selected === "Link/URL" ? "#fff" : "#9EA1A8",
                      border: "1px solid #9EA1A8",
                      fontSize: "14px",
                      padding: "10px",
                      "&:hover": {
                        backgroundColor:
                          selected === "Link/URL" ? "#673C8E" : "#f5f5f5",
                      },
                    }}
                  />
                  <Chip
                    label="Embedded Code"
                    onClick={() => handleChipClick("Embedded Code")}
                    sx={{
                      backgroundColor:
                        selected === "Embedded Code" ? "#673C8E" : "#fff",
                      color: selected === "Embedded Code" ? "#fff" : "#9EA1A8",
                      border: "1px solid #9EA1A8",
                      fontSize: "14px",
                      padding: "10px",
                      "&:hover": {
                        backgroundColor:
                          selected === "Embedded Code" ? "#673C8E" : "#f5f5f5",
                      },
                    }}
                  />
                </Box>
              </Box>

              <Typography
                variant="h5"
                sx={{
                  fontSize: "16px",
                  marginBottom: "2px",
                }}
              >
                Visibility
                <span style={{ color: "red" }}>*</span>
              </Typography>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="visibility"
                      value="Drivers"
                      checked={values.visibility.includes("Drivers")}
                      onChange={(event) =>
                        handleCheckboxChange(event, setFieldValue, values)
                      }
                      sx={{
                        color: "#683C8C",
                        "&.Mui-checked": {
                          color: "#683C8C",
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#683C8C",
                      fontSize: "14px",
                    },
                  }}
                  label="Drivers"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="visibility"
                      value="Clients"
                      checked={values.visibility.includes("Clients")}
                      onChange={(event) =>
                        handleCheckboxChange(event, setFieldValue, values)
                      }
                      sx={{
                        color: "#683C8C",
                        "&.Mui-checked": {
                          color: "#683C8C",
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#683C8C",
                      fontSize: "14px",
                    },
                  }}
                  label="Clients"
                />
              </FormGroup>

              <Typography
                variant="h5"
                sx={{
                  fontSize: "16px",
                  marginBottom: "2px",
                }}
              >
                Duration<span style={{ color: "red" }}>*</span>
              </Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.duration || 0}
                  name="discountType"
                  onChange={(e) => setFieldValue("duration", e.target.value)}
                  sx={{
                    mt: 1,
                    color: "#1C1B1F",
                    mb: 1,
                    "& .MuiSelect-select": {
                      borderColor: "#673C8E",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                  }}
                  // onChange={handleChange}
                >
                  <MenuItem value={0}>Select the time duration</MenuItem>
                  <MenuItem value="5 mins">5 mins</MenuItem>
                  <MenuItem value="10 mins">10 mins</MenuItem>
                  <MenuItem value="15 mins">15 mins</MenuItem>
                  <MenuItem value="20 mins">20 mins</MenuItem>
                  <MenuItem value="25 mins">25 mins</MenuItem>
                  <MenuItem value="30 mins">30 mins</MenuItem>
                  <MenuItem value="35 mins">35 mins</MenuItem>
                  <MenuItem value="40 mins">40 mins</MenuItem>
                  <MenuItem value="45 mins">45 mins</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          {/* video tutorial player box */}
          <Box
            sx={{
              margin: "0 20px 25px",
              // height: "304px",
              background: "#FEFEFE",
              boxShadow:
                "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
              borderRadius: "4px",
              padding: "10px 10px 55px",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#000",
                textAlign: "center",
              }}
            >
              Video Tutorials
            </Typography>
            <Box
              sx={{
                background: "#F9F9F9",
                boxShadow:
                  "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
                borderRadius: "4px",
                padding: "13px",
                marginTop: "52px",
                height: "203px",
              }}
            >
              {(selected === "Link/URL" ||
                tutorial?.videoSourceType === "Link") && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#000",
                    }}
                  >
                    Video Link<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {/* Replace 'link' with the actual image source */}
                    <img src={link} alt="Link/URL" />
                    <Typography
                      sx={{
                        fontSize: "11px",
                        color: "#BDC1CA",
                      }}
                    >
                      Copy your URL/link that needs to be added
                    </Typography>

                    <Box
                      sx={{
                        background: "#F9F9F9",
                        border: "1px solid #683C8C",
                        borderRadius: "4px",
                        padding: "3px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "80%",
                      }}
                    >
                      <TextField
                        name="video"
                        placeholder="Enter link"
                        sx={{
                          color: "#1C1B1F",
                          "& .MuiInputLabel-root": {
                            color: "#1C1B1F !important",
                            border: "none",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none", // No border for normal state
                            },
                            "&:hover fieldset": {
                              border: "none", // No border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // No border when focused
                            },
                          },
                          "& .MuiInputBase-input": {
                            padding: "6px", // Adjust padding inside TextField
                          },
                        }}
                        size="small"
                        fullWidth
                        value={values.video}
                        onChange={(event) => {
                          handleChange(event); // Keep the value updated in Formik state
                        }}
                        onBlur={handleBlur}
                        variant="outlined"
                        // error={Boolean(errors.video)} // Show red outline if there's an error
                        // helperText={errors.video} // Display error message if URL is invalid
                      />
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px 26px", // Adjusted padding to fit text in one line
                          textTransform: "capitalize",
                          backgroundColor: "rgba(103, 60, 142, 1)",
                          color: "#fff",
                          borderRadius: "4px",
                          fontWeight: 600,
                          fontSize: "11px",
                          whiteSpace: "nowrap", // Prevent text from wrapping
                          "&:hover": {
                            backgroundColor: "rgba(103, 60, 142, 1)",
                            color: "#fff",
                          },
                        }}
                        onClick={() => {
                          const urlPattern =
                            /^(https?:\/\/)?([\w\d\-]+\.){1,}([a-z]{2,})(:\d{1,5})?(\/\S*)?$/i;

                          if (!urlPattern.test(values.video)) {
                            setMessage(
                              "Invalid URL. Please enter a valid link."
                            );
                            setMessageColor("red"); // Set color to red for error
                            setFieldError("video", "Invalid URL");
                          } else {
                            setMessage("Success! Valid URL.");
                            setMessageColor("#4CAF50"); // Green for success message
                            setFieldError("video", ""); // Clear any error in Formik
                            // Proceed with any additional logic for adding the link
                          }
                        }}
                      >
                        Add a Link
                      </Button>
                    </Box>
                    {/* Message shown outside the box (either error or success) */}
                    <Typography
                      sx={{
                        fontSize: "11px",
                        color: messageColor, // Dynamic color based on success or error
                        textAlign: "center", // Center align the text
                        marginTop: "8px", // Add some spacing
                      }}
                    >
                      {message}
                    </Typography>
                  </Box>
                </Box>
              )}

              {(selected === "Embedded Code" ||
                tutorial?.videoSourceType === "Embedded") && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#000",
                    }}
                  >
                    Video Embed code<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {/* Replace 'code' with the actual image source */}
                    <img src={code} alt="Embed Code" />
                    <TextField
                      name="video"
                      value={values.video}
                      multiline
                      minRows={4}
                      sx={{
                        color: "#1C1B1F",
                        background: "rgba(104, 60, 140, 0.2)",
                        mb: 3,
                        "& .MuiInputLabel-root": {
                          background: "rgba(104, 60, 140, 0.2)",
                          color: "#1C1B1F !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary,
                          },
                          "&.Mui-focused fieldset": {
                            background: "rgba(104, 60, 140, 0.2)",
                            borderColor: "#1C1B1F",
                          },
                        },
                      }}
                      size="small"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          {/* video tutorial player box */}

          <Box
            sx={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            {/* Upload thumbnail */}
            <Box sx={{ width: "60%" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "16px",
                  marginBottom: "2px",
                  fontWeight: 500,
                  color: "#000",
                }}
              >
                Upload Thumbnail<span style={{ color: "red" }}>*</span>
              </Typography>
              <Box
                sx={{
                  background: "#F9F9F9",
                  border: "1px solid #683C8C",
                  borderRadius: "4px",
                  padding: "3px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "11px",
                    color: selectedFile ? "#000" : "#A4A4A4", // Display file name or placeholder text
                  }}
                >
                  {selectedFile ? selectedFile.name : "Select file to upload"}
                </Typography>
                <Box>
                  {/* Only show the select button if no file has been selected or uploaded */}
                  {!selectedFile && !fileUploaded && (
                    <label htmlFor="file-upload">
                      <input
                        accept="image/*"
                        type="file"
                        style={{ display: "none" }}
                        id="file-upload"
                        onChange={handleFileSelect}
                      />
                      <Button
                        component="span"
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px",
                          textTransform: "capitalize",
                          backgroundColor: "rgba(103, 60, 142, 1)",
                          color: "#fff",
                          borderRadius: "4px",
                          fontWeight: 600,
                          fontSize: "11px",
                          marginRight: "2px",
                          "&:hover": {
                            backgroundColor: "rgba(103, 60, 142, 1)",
                            color: "#fff",
                          },
                        }}
                      >
                        Select
                      </Button>
                    </label>
                  )}

                  {/* Show the Upload button only when a file is selected but not uploaded */}
                  {selectedFile && !fileUploaded && (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        padding: "5px 26px",
                        textTransform: "capitalize",
                        backgroundColor: "rgba(103, 60, 142, 1)",
                        color: "#fff",
                        borderRadius: "4px",
                        fontWeight: 600,
                        fontSize: "11px",
                        marginLeft: "8px", // Add space between select and upload buttons
                        "&:hover": {
                          backgroundColor: "rgba(103, 60, 142, 1)",
                          color: "#fff",
                        },
                      }}
                      onClick={handleUpload} // Handle the upload logic
                    >
                      Upload
                    </Button>
                  )}

                  {/* Show the Close button only when file is uploaded */}
                  {fileUploaded && (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          padding: "5px 16px",
                          textTransform: "capitalize",
                          backgroundColor: "rgba(103, 60, 142, 1)",
                          color: "#fff",
                          borderRadius: "4px",
                          fontWeight: 600,
                          fontSize: "11px",
                          marginLeft: "8px",
                          "&:hover": {
                            backgroundColor: "rgba(103, 60, 142, 1)",
                            color: "#fff",
                          },
                        }}
                        onClick={handleClose}
                      >
                        <Close sx={{ fontSize: "20px" }} />
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            {/* Upload thumbnail */}

            {/* Preview thumbnail */}
            <Box sx={{ width: "40%" }}>
              <Box
                sx={{
                  border: "1px solid #673C8E",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="Thumbnail preview"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                ) : (
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#000",
                    }}
                  >
                    Thumbnail Image
                  </Typography>
                )}
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "13px",
                  color: "#fff",
                  background: "#673C8E",
                  fontWeight: 600,
                  textAlign: "center",
                  padding: "12px",
                  width: "100%",
                }}
              >
                Preview Thumbnail
              </Typography>
            </Box>
            {/* Preview thumbnail */}
          </Box>
        </Grid>

        {/* ----------------action buttons--------------------- */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // paddingRight: "24px",
            gap: 2,
            position: "absolute",
            right: 0,
            bottom: "-30px",
            width: "100%",
            paddingBottom: "20px",
          }}
        >
          {id && (
            <Button
              variant="outlined"
              size="small"
              sx={{
                padding: "10px 30px",
                textTransform: "capitalize",
                backgroundColor: "transparent",
                color: "rgba(103, 60, 142, 1)",
                borderColor: "rgba(103, 60, 142, 1)",
                borderRadius: "4px",
                fontWeight: 600,
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "rgba(103, 60, 142, 1)",
                  borderColor: "rgba(103, 60, 142, 1)",
                },
              }}
              onClick={(e) => handleOpenRegularOfferModal(tutorial)}
            >
              {tutorial && tutorial.archived ? "Unarchived" : "Archive"}
            </Button>
          )}
          <Button
            variant="outlined"
            size="small"
            onClick={handleBackClick}
            sx={{
              padding: "10px 30px",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              color: "rgba(103, 60, 142, 1)",
              borderColor: "rgba(103, 60, 142, 1)",
              borderRadius: "4px",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "transparent",
                color: "rgba(103, 60, 142, 1)",
                borderColor: "rgba(103, 60, 142, 1)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              padding: "10px 30px",
              textTransform: "capitalize",
              backgroundColor: "rgba(103, 60, 142, 1)",
              color: "#fff",
              borderRadius: "4px",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "rgba(103, 60, 142, 1)",
                color: "#fff",
              },
            }}
            onClick={handleOfferSubmit}
          >
            {id ? "Update" : "Publish"}
          </Button>
        </Box>
        {/* ----------------action buttons--------------------- */}
      </Grid>
      {openRegularOfferModal && selectedRow && (
        <TutorialArchiveModal
          open={openRegularOfferModal}
          archived={selectedRow.archived}
          onSubmit={handleOfferArchiveSubmit}
          onClose={handleCloseModal}
        />
      )}
    </form>
  );
}
