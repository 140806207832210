import React, { useState } from "react";
import { Box, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import ClientReviews from "./client/ClientReviews";
import DriverReviews from "./driver/DriverReviews";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import back from "../../assets/images/svg/back.svg";
import Filter from "../../components/Filter";
import { clientFilters, reviewFilter } from "../../utils/filterConfig";

export function Reviews() {
  const [filtrationData, setFiltrationData] = useState({
    from: "",
    to: "",
  });
  const [topReviews, setTopReviews] = useState([]);
  const [value, setValue] = useState(1);
  const [showAllClientReviews, setShowAllClientReviews] = useState(false);
  const [showAllDriverReviews, setShowAllDriverReviews] = useState(false);

  const handleViewAllClientReviewsClick = () => {
    setShowAllClientReviews(true);
  };

  const handleViewAllDriverReviewsClick = () => {
    setShowAllDriverReviews(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setShowAllDriverReviews(false);
    } else {
      setShowAllClientReviews(false);
    }
  };

  const addReviewToTop = (review) => {
    setTopReviews((prevReviews) => {
      if (prevReviews.some((r) => r.id === review.id)) {
        return prevReviews;
      }
      if (prevReviews.length < 3) {
        return [...prevReviews, review];
      }
      return [review, ...prevReviews.slice(0, 2)];
    });
  };

  const handleBackClick = () => {
    if (value === 0) {
      setShowAllDriverReviews(false);
    } else {
      setShowAllClientReviews(false);
    }
  };

  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container spacing={2} sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={4}>
          {(value === 0 && showAllDriverReviews) ||
          (value === 1 && showAllClientReviews) ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <IconButton
                onClick={handleBackClick}
                sx={{ marginLeft: "-10px" }}
              >
                <img src={back} />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    fontSize: "30px",
                    color: "#673C8E",
                  }}
                >
                  Other Reviews
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E",
              }}
            >
              Reviews
            </Typography>
          )}
        </Grid>
        <Grid xs={12} md={6} sx={{ textAlign: "center", marginTop: "20px" }}>
          <Box
            sx={{
              width: "30%",
              bgcolor: "rgba(104, 60, 140, 0.06)",
              borderRadius: "8px",
              padding: "8px 10px",
              minHeight: "60px",
              maxHeight: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              sx={{
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tab
                label="Drivers"
                sx={{
                  textTransform: "capitalize",
                  color: value === 0 ? "#fff" : "#000",
                  fontSize: "14px",
                  fontWeight: 700,
                  backgroundColor: value === 0 ? "#673C8E" : "transparent",
                  borderBottom: "none",
                  borderRadius: "16px",
                  minWidth: "67px",
                  width: "67px",
                  maxWidth: "67px",
                  minHeight: "34px",
                  height: "34px",
                  maxHeight: "34px",
                  mr: 2,
                }}
              />
              <Tab
                label="Clients"
                sx={{
                  textTransform: "capitalize",
                  color: value === 1 ? "#fff" : "#000",
                  fontSize: "14px",
                  fontWeight: 700,
                  backgroundColor: value === 1 ? "#673C8E" : "transparent",
                  borderBottom: "none",
                  borderRadius: "16px",
                  minWidth: "67px",
                  width: "67px",
                  maxWidth: "67px",
                  minHeight: "34px",
                  height: "34px",
                  maxHeight: "34px",
                }}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={{ textAlign: "right", paddingRight: "18px" }}
        >
          <Filter
            onResetFilters={() => {
              setFiltrationData((prev) => ({
                from: "",
                to: "",
              }));
            }}
            onFilterData={getFilteredData}
            type="reviewFilter"
            url="reviewFilter"
            config={reviewFilter}
          />
        </Grid>
        {value === 0 && (
          <DriverReviews
            showAllReviews={showAllDriverReviews}
            onViewAllClick={handleViewAllDriverReviewsClick}
            topReviews={topReviews}
            addReviewToTop={addReviewToTop}
            filtrationData={filtrationData}
          />
        )}
        {value === 1 && (
          <ClientReviews
            showAllReviews={showAllClientReviews}
            onViewAllClick={handleViewAllClientReviewsClick}
            topReviews={topReviews}
            addReviewToTop={addReviewToTop}
            filtrationData={filtrationData}
          />
        )}
      </Grid>
    </DndProvider>
  );
}
