import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const columns = [
  {
    label: "Category Name",
    key: "name",
  },
  {
    label: "Created Date",
    key: "createdAt",
  },
  {
    label: "Assigned Orders",
    key: "assignedOrders",
  },
  {
    label: "Status",
    key: "status",
  },
];

const CategoryItemTable = () => {
  const { state } = useLocation();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: "rgba(103, 60, 142, 1)" }}>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", width: "50%", color: "#ffffff" }}
            >
              Category Details
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", color: "#ffffff" }}
            >
              Item Details
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: "#ffffff" }}>
          {columns.map((row, index) => (
            <TableRow
              sx={{
                border: "1px solid rgba(103, 60, 142, 1)",
              }}
              key={index}
            >
              <TableCell
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid rgba(103, 60, 142, 1)",
                  borderRight: "1px solid rgba(103, 60, 142, 1)",
                }}
                align="center"
              >
                {row.label}
              </TableCell>
              <TableCell
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid rgba(103, 60, 142, 1)",
                }}
                align="center"
              >
                {state[row.key]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CategoryItemTable;
