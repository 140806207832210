import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import {
  ForgotPassword,
  LoginPage,
  ResetPassword,
  AuthenticatorScreen,
  Orders,
  Dashboard,
  LogoutPage,
  OrderDetail,
  Clients,
  Reports,
  Drivers,
  Calendar,
  Chats,
  Tutorials,
  Reviews,
  AccountSettings,
  Statements,
  HelpCenter,
  DeleteAccount,
  PickUpDetails,
  ChatDetails,
  DropOffDetails,
  ClientDetail,
  DriverDetail,
  RegularOffers,
  HelpOffers,
  CreateRegularOffer,
  CreateHelpOffer,
  PrivacyPolicy,
  PromoTerms,
  Acceptable,
  TermsUse,
  OutAreaPolicy,
  RefundCancel,
  BusinessClientStories,
  MoreWaysUse,
  CreateTutorials,
  DriverContract,
} from "../pages";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import { Sidebar, Footer, Header } from "../components";
import { LoaderContainer } from "react-global-loader";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, CssBaseline, useTheme } from "@mui/material";
import ReportAction from "../pages/reports/ReportAction";
import { AcceptedCategories } from "../pages/acceptedCategories/AcceptedCategories";
import { EditCategory } from "../pages/acceptedCategories/EditCategoryPage";

const MainLayout = () => {
  // const theme = createAppTheme(); // Use your custom theme here
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: theme.palette.primary.main,
        height: "100vh",
        width: "100vw",
      }}
    >
      <CssBaseline />
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Header theme={theme} />
        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
          <Outlet /> {/* This is where the nested routes will be rendered */}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <LoaderContainer>
        <CircularProgress color="secondary" />
      </LoaderContainer>
      <Routes>
        {/* ==================================== PROTECTED ROUTES ==================================== */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="services/orders" element={<Orders />} />
            <Route path="services/clients" element={<Clients />} />
            <Route path="client-detail" element={<ClientDetail />} />
            <Route path="services/reports" element={<Reports />} />
            <Route
              path="services/reports/reportaction"
              element={<ReportAction />}
            />
            <Route path="services/drivers" element={<Drivers />} />
            <Route path="driver-detail" element={<DriverDetail />} />
            <Route path="services/calendar" element={<Calendar />} />
            <Route path="services/chats" element={<Chats />} />
            <Route
              path="chats/chat-detail/:sessionID"
              element={<ChatDetails />}
            />
            <Route path="services/regular-offers" element={<RegularOffers />} />
            <Route
              path="services/regular-offers/add"
              element={<CreateRegularOffer />}
            />
            <Route path="services/help-offers" element={<HelpOffers />} />
            <Route
              path="services/help-offers/add"
              element={<CreateHelpOffer />}
            />
            <Route path="services/tutorials" element={<Tutorials />} />
            <Route
              path="services/tutorials/add"
              element={<CreateTutorials />}
            />
            <Route path="services/reviews" element={<Reviews />} />
            <Route
              path="services/business-client"
              element={<BusinessClientStories />}
            />
            <Route path="services/more-ways" element={<MoreWaysUse />} />
            {/* -------------OTHERS-------------------- */}
            <Route
              path="others/account-settings"
              element={<AccountSettings />}
            />
            <Route
              path="others/accepted-categories"
              element={<AcceptedCategories />}
            />
            <Route
              path="others/accepted-categories/edit"
              element={<EditCategory />}
            />
            <Route path="others/statements" element={<Statements />} />
            <Route path="others/help-center" element={<HelpCenter />} />
            <Route path="others/delete-account" element={<DeleteAccount />} />
            <Route path="others/logout" element={<LogoutPage />} />
            {/* -------------OTHERS-------------------- */}
            {/* ===========================Legals ============================== */}
            <Route path="legals/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="legals/terms-of-use" element={<TermsUse />} />
            <Route path="legals/promo-terms" element={<PromoTerms />} />
            <Route path="legals/refund-cancel" element={<RefundCancel />} />
            <Route path="legals/acceptable-item" element={<Acceptable />} />
            <Route path="legals/out-area-policy" element={<OutAreaPolicy />} />
            <Route path="legals/driver-contract" element={<DriverContract />} />
            {/* ===========================Legals ============================== */}
            <Route path="order-detail" element={<OrderDetail />} />
            <Route
              path="order-detail/pickup-details"
              element={<PickUpDetails />}
            />
            \
            <Route
              path="order-detail/dropoff-details"
              element={<DropOffDetails />}
            />
          </Route>
        </Route>
        {/* ==================================== PROTECTED ROUTES ==================================== */}

        {/* ========================== PUBLIC ROUTES ========================== */}
        <Route path="/" element={<PublicRoute />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="authenticator" element={<AuthenticatorScreen />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
        {/* ========================== PUBLIC ROUTES ========================== */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
