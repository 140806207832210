import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Grid,
  Box,
  Button,
  Typography,
  FormHelperText,
  LinearProgress,
} from "@mui/material";
import { API_URL } from "../../constants";
import { enqueueSnackbar } from "notistack";
import errorImg from "../../assets/images/resetPassword.svg";
import success from "../../assets/images/svg/success.svg";
import blank from "../../assets/images/svg/blank.svg";
import logo from "../../assets/images/logo.svg";

export function ResetPassword() {
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [buttonVariant, setButtonVariant] = useState("outlined"); // Default variant is outlined
  const [isPasswordTyping, setIsPasswordTyping] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isConfirmPasswordTyping, setIsConfirmPasswordTyping] = useState(false);
  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [passwordNumberValid, setPasswordNumberValid] = useState(false);
  const [passwordSpecialCharValid, setPasswordSpecialCharValid] =
    useState(false);

  const calculateProgress = (password) => {
    let progress = 0;

    if (password.length >= 8) progress += 30;
    if (/\d/.test(password)) progress += 40;
    if (/[!@#$%^&*(),.?":{}<>]/.test(password)) progress += 30;

    return progress;
  };
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    formik.handleChange(e);
    const calculatedProgress = calculateProgress(password);
    if (!isPasswordTyping) {
      setIsPasswordTyping(true);
    }
    setProgress(calculatedProgress); // Update the progress state variable
    setPasswordLengthValid(password.length >= 8);
    setPasswordNumberValid(password.length >= 8 && /\d/.test(password));
    setPasswordSpecialCharValid(
      password.length >= 8 && /[!@#$%^&*(),.?":{}<>]/.test(password)
    );
  };

  const getProgressColor = (progress) => {
    if (progress >= 100) return "green"; // Green for 100%
    if (progress >= 70) return "orange"; // Orange for 70%
    if (progress >= 30) return "red"; // Red for 30%
    return "#EDECEF"; // Default color
  };

  // console.log(progress);
  const location = useLocation();
  const navigate = useNavigate();
  const { adminId } = location.state || {}; // get the adminId from url
  const [showPassword, setShowPassword] = useState(false); // state to manage password
  const [showNewPass, setShowNewPass] = useState(false); // state to manage new password
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Push a dummy state
    window.history.pushState(null, null, window.location.pathname);

    const handlePopState = () => {
      // Prevent back navigation by pushing state
      window.history.pushState(null, null, window.location.pathname);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  // FRONTEND VALIDATIONS ---------------------------------
  const validationSchema = Yup.object({
    password: Yup.string().required("Required!"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Passwords don’t match. Please try again"
      )
      .required("Required!"),
  });
  // FRONTEND VALIDATIONS ---------------------------------

  const formik = useFormik({
    // INITIAL VALUES ----------------------------
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    // INITIAL VALUES ----------------------------

    validationSchema,

    // FUCNTION TO CALL RESET-PASSWORD API ------------------------------------
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${API_URL}create/admin/reset-password`,
          { ...values, adminId }
        );
        const { data, type, message } = response.data;
        enqueueSnackbar(message, { variant: type });
        if (type === "success") {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error during password reset:", error);
        enqueueSnackbar("Password reset failed. Please try again.", {
          variant: "error",
        });
      }
    },
    // FUCNTION TO CALL RESET-PASSWORD API ------------------------------------
  });

  const handleConfirmPasswordChange = (e) => {
    const newPassword = formik.values.password;
    const confirmNewPassword = e.target.value;
    setIsConfirmPasswordTyping(true);
    setIsPasswordTyping(false);
    setIsFormValid(!!newPassword && !!confirmNewPassword);
    setIsPasswordMatch(newPassword === confirmNewPassword);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show); //FUNCTION TO HIDE/SHOW PASSWORD
  const handleClickShowNewPassword = () => setShowNewPass((show) => !show); //FUNCTION TO HIDE/SHOW NEW PASSWORD
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          height: "100vh",
          padding: "0",
          width: "100vw",
          display: "flex",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ position: "absolute", right: "20px", top: "10px" }}>
          <img style={{ width: "120px" }} src={logo} alt="Logo" />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            md={7.5}
            sx={{
              background: (theme) => theme.palette.background.paper,
              boxShadow: "0px 4px 10px 0px #673C8E",
              borderRight: "1px solid #673C8E",
              display: "grid",
              placeItems: "center",
            }}
          >
            {/* ===================================== RESET PASSWORD BOX ================================= */}
            <Box
              sx={{
                maxWidth: {
                  xs: "270px",
                  md: "300px",
                  lg: "512px",
                },
                marginTop: {
                  xs: "40px",
                  md: "0",
                },
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    sx={{
                      // color: (theme) => theme.palette.text.secondary,
                      color: "#313131",
                      fontWeight: 600,
                    }}
                  >
                    Reset a password
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#313131",
                      marginTop: "16px",
                    }}
                  >
                    Your previous password has been reset. Please set a new
                    password for your account.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel
                      sx={{
                        color: "#1C1B1F !important", // Change label text color
                      }}
                      size="small"
                      htmlFor="password"
                    >
                      Create Password
                    </InputLabel>
                    <OutlinedInput
                      placeholder="Create Password"
                      sx={{
                        color: "#1C1B1F",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1C1B1F", // Change outlined border color when focused
                        },
                      }}
                      size="small"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handlePasswordChange(e);
                        // handlePassword(e);
                      }}
                      onBlur={formik.handleBlur}
                      type={showPassword ? "text" : "password"}
                      label="Create Password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      error={
                        formik.touched.password && !!formik.errors.password
                      }
                    />
                    {formik.touched.password && (
                      <FormHelperText error>
                        {formik.errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {isPasswordTyping &&
                    !isConfirmPasswordTyping &&
                    progress <= 100 && (
                      <Box>
                        <LinearProgress
                          sx={{
                            marginTop: "11px",
                            marginBottom: "9px",
                            bgcolor: "#EDECEF", // Constant background color
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: getProgressColor(progress),
                            },
                            height: "8px",
                            borderRadius: "4px",
                          }}
                          variant="determinate"
                          value={progress}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            marginLeft: "-8px",
                          }}
                        >
                          <IconButton>
                            <img src={passwordLengthValid ? success : blank} />
                          </IconButton>
                          <Typography
                            sx={{
                              color: "#4C4A53",
                              fontSize: "16px",
                            }}
                          >
                            8 characters minimum
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            marginLeft: "-8px",
                          }}
                        >
                          <IconButton>
                            <img src={passwordNumberValid ? success : blank} />
                          </IconButton>
                          <Typography
                            sx={{
                              color: "#4C4A53",
                              fontSize: "16px",
                            }}
                          >
                            a number
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            marginLeft: "-8px",
                          }}
                        >
                          <IconButton>
                            <img
                              src={passwordSpecialCharValid ? success : blank}
                            />
                          </IconButton>
                          <Typography
                            sx={{
                              color: "#4C4A53",
                              fontSize: "16px",
                            }}
                          >
                            a symbol
                          </Typography>
                        </Box>
                      </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel
                      sx={{
                        color: "#1C1B1F !important", // Change label text color
                      }}
                      size="small"
                      htmlFor="confirmPassword"
                    >
                      Re-enter Password
                    </InputLabel>
                    <OutlinedInput
                      placeholder="Re-enter Password"
                      sx={{
                        color: "#1C1B1F",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: (theme) => theme.palette.text.secondary, // Change outlined border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1C1B1F", // Change outlined border color when focused
                        },
                      }}
                      size="small"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleConfirmPasswordChange(e);
                      }}
                      disabled={progress === 100 ? false : true}
                      onBlur={formik.handleBlur}
                      type={showNewPass ? "text" : "password"}
                      label="Re-enter Password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showNewPass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      error={
                        formik.touched.confirmPassword &&
                        !!formik.errors.confirmPassword
                      }
                    />
                    {formik.touched.confirmPassword && (
                      <FormHelperText error>
                        {formik.errors.confirmPassword}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    // variant={isFormValid ? "contained" : "outlined"}
                    variant={isPasswordMatch ? "contained" : buttonVariant}
                    type="submit"
                    fullWidth
                    sx={{
                      backgroundColor: isPasswordMatch
                        ? "#683C8C"
                        : "transparent",
                      color: isPasswordMatch ? "#fff" : "#683C8C",
                      borderColor: "#683C8C",
                      padding: "10px 8px",
                      "&:hover": {
                        backgroundColor: isPasswordMatch
                          ? "#683C8C"
                          : "transparent",
                        color: isPasswordMatch ? "#fff" : "#683C8C",
                        borderColor: "#683C8C",
                      },
                    }}
                  >
                    Set Password
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {/* ===================================== RESET PASSWORD BOX ================================= */}
          </Grid>
          <Grid
            item
            xs={12}
            md={4.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{ position: "relative" }}
          >
            <Box
              sx={{
                marginTop: { xs: "30px", md: "0" },
                width: { xs: "150px", md: "360px", lg: "400px" },
              }}
            >
              <img style={{ width: "inherit" }} src={errorImg} alt="Login" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
