import React from "react";
import { Box, Grid, Typography, Slider } from "@mui/material";
import { styled } from "@mui/system";

const valuetext = (value) => {
  return `${value}`;
};

const generateMarks = (type) => {
  const labelPrefix = type === "drivers" ? "" : "$";
  return [
    { value: 10, label: `${labelPrefix}10` },
    { value: 100, label: `${labelPrefix}100` },
    { value: 200, label: `${labelPrefix}200` },
    { value: 300, label: `${labelPrefix}300` },
    { value: 400, label: `${labelPrefix}400` },
    { value: 500, label: `${labelPrefix}500` },
    { value: 600, label: `${labelPrefix}600` },
    { value: 700, label: `${labelPrefix}700` },
    { value: 800, label: `${labelPrefix}800` },
    { value: 900, label: `${labelPrefix}900` },
  ];
};

const CustomSlider = styled(Slider)({
  "& .MuiSlider-valueLabel": {
    backgroundColor: "#673C8E",
    color: "#fff",
  },
  "& .MuiSlider-markLabel": {
    fontSize: "8px",
  },
});

const AmountRangeFilter = ({ type, selectedFilters, setSelectedFilters }) => {
  const handleSliderChange = (event, newValue) => {
    const [newAmountFrom, newAmountTo] = newValue;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      amountFrom: newAmountFrom,
      amountTo: newAmountTo,
    }));
  };

  return (
    <>
      {(type === "orders" || type === "clients" || type === "drivers") && (
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "rgba(23, 26, 31, 1)",
              fontSize: "10px",
              mb: 1,
              fontWeight: 700,
            }}
          >
            {type === "orders"
              ? "Amount Range"
              : type === "clients"
              ? "Filter with Total Amount Spent"
              : "Filter with Total Order Count"}
          </Typography>

          <Box sx={{ mt: 5 }}>
            <CustomSlider
              getAriaLabel={() => "Amount range"}
              value={[selectedFilters.amountFrom, selectedFilters.amountTo]}
              onChange={handleSliderChange}
              valueLabelDisplay="on"
              valueLabelFormat={valuetext}
              getAriaValueText={valuetext}
              min={0}
              max={900}
              sx={{ color: "#673C8E" }}
              marks={generateMarks(type)} // Use the dynamically generated marks
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default AmountRangeFilter;
