import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  StepConnector,
  Paper,
  Card,
  Select,
  MenuItem,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AirportShuttleOutlined,
  PhoneOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import DynamicTable from "../../components/DynamicTable";
import usePagination from "@mui/material/usePagination/usePagination";
import truck from "../../assets/images/svg/truck.svg";
import reload from "../../assets/images/svg/reload.svg";
import add from "../../assets/images/svg/add.svg";
import info from "../../assets/images/svg/info.svg";
import driver from "../../assets/images/svg/driver.svg";
import reviewRed from "../../assets/images/svg/reviewRed.svg";
import reject from "../../assets/images/svg/reject.svg";
import star from "../../assets/images/svg/star.svg";
import starOutline from "../../assets/images/svg/starOutline.svg";
import back from "../../assets/images/svg/back.svg";
import { $crud } from "../../utils/CrudFactory";
import PositionModal from "../../components/modals/OrderPositionModal";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import MapComponent from "../../components/maps/MapComponent";

// CUSTOM STEPPER FOR ORDER DETAIL ------------------------------
const CustomStepConnector = () => (
  <StepConnector
    sx={{
      "& .MuiStepConnector-line": {
        display: "block",
        borderColor: "#683C8C",
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        minHeight: "24px",
      },
    }}
  />
);
// CUSTOM STEPPER FOR ORDER DETAIL ------------------------------

// CUSTOM TOOLTIP ====================================
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 140, // Custom maxWidth for Tooltip
    minHeight: 56, // Custom maxWidth for Tooltip
    background: "#795898", // Additional styles if needed
    color: "#fff", // Additional styles if needed
    fontSize: "8px", // Additional styles if needed
    padding: "8px", // Additional styles if needed
    position: "absolute",
    top: "-38px",
    left: "-90px",
    borderRadius: "0",
  },
});

// STEPS FOR THE STATUS OF ORDER -------------------------------
const steps = [
  {
    label: "Working on Pickup 2",
    description: `On The Way To Pickup 2`,
    icon: <img src={truck} />,
  },
  {
    label: "Pickup 1 complete",
    description: "Sat,23 Jul 2020, 01:24 PM - 1hr 20m",
    icon: <img src={truck} />,
  },
];
// STEPS FOR THE STATUS OF ORDER -------------------------------

export function OrderDetail() {
  const [mergedSteps, setMergedSteps] = useState([]);
  const [client, setClient] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [orderItems, setOrderItems] = useState([]);
  const [filtrationData, setFiltrationData] = useState({
    search: "",
  });
  const [order, setOrder] = useState(
    location.state?.selectedOrder ||
      JSON.parse(localStorage.getItem("selectedOrder"))
  );

  useEffect(() => {
    // Save order to local storage whenever it updates
    localStorage.setItem("selectedOrder", JSON.stringify(order));
  }, [order]);

  useEffect(() => {
    // Combine order.positionHistory with existing steps
    const merged = [...steps];

    if (order && order.positionHistory) {
      order.positionHistory.forEach((historyItem, index) => {
        let label = "";
        let icon = "";
        let desc = "";
        if (historyItem.status == "No Holds") {
          label = "Order Reinstated";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = <img src={reload} />;
        }
        if (historyItem.status == "Reject") {
          label = "Order Rejected";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = <img src={reject} />;
        }
        if (historyItem.status == "Review") {
          label = "Order kept on Review";
          desc = "Fri,22 Jul 2020,10:44 AM - 35m";
          icon = <img src={reviewRed} />;
        }
        merged.push({
          label,
          description: desc,
          icon,
          reason: historyItem.reason,
          tooltip: true,
        });
      });
    }

    merged.push(
      {
        label: "Driver Matched",
        description: `Fri, 22 Jul 2020, 10:44 AM - 35m`,
        icon: <img src={driver} width="24px" />, // Replace with your icon or JSX
      },
      {
        label: "Order Booked",
        description: `Fri, 22 Jul 2020, 10:44 AM - 35m`,
        icon: <img src={add} />, // Replace with your icon or JSX
      }
    );

    setMergedSteps(merged);
  }, [order, steps]);

  async function retrieveOrderItems() {
    const isFilterApplied = Object.values(filtrationData).some(
      (value) => value !== "" && value !== false
    );

    const params = {
      search: filtrationData.search,
    };

    // Conditionally include limit and page if no filters are applied
    if (!isFilterApplied) {
      params.limit = limit;
      params.page = page;
    }
    const { data } = await $crud.retrieve("admin/order-items", {
      orderId: order.orderId,
      search: filtrationData.search,
    });
    return data;
  }

  async function retrieveClientDetails() {
    const { data } = await $crud.retrieve("admin/client", {
      customerID: order.customerId,
    });
    return data;
  }

  useEffect(() => {
    retrieveOrderItems().then((res) => {
      setOrderItems(res.orderItems);
    });
    retrieveClientDetails().then((res) => {
      setClient(res);
    });
  }, [order, filtrationData]);

  const [activePosition, setActivePosition] = useState(order.position.status);
  const [newPosition, setNewPosition] = useState(""); // state to open position modal
  const [positionModalOpen, setPositionModalOpen] = useState(false); // state to open position modal
  const [positionPlaceholder, setPositionPlaceholder] = useState(""); // state to open position modal
  const [positionBtn, setPositionBtn] = useState(""); // state to open position modal
  const [positionModalTitle, setPositionModalTitle] = useState(""); //state to display position modal title
  const [selectedRow, setSelectedRow] = useState(null); // To store the selected row for which position is being updated
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS
  const [searchInput, setSearchInput] = useState("");

  // function to close position modal---------------------------------
  const handleCloseModal = () => {
    setPositionModalTitle(false);
    setSelectedRow(null);
    setPositionModalOpen("");
  };
  // function to call the api and update the comment -------------------------------------
  const handlePositionSubmit = async (reason) => {
    // Logic to update position with reason
    const { data, type } = await $crud.put(`admin/order`, {
      position: newPosition,
      comment: reason,
      _id: selectedRow._id,
    });
    // console.log(data);
    if (type === "success") {
      const updatedOrder = data?.order;
      if (Array.isArray(updatedOrder.position)) {
        updatedOrder.position = updatedOrder.position[0];
      }
      console.log(
        updatedOrder.position.status,
        "new ypdated position-----------------"
      );

      // Update state with normalized data
      setOrder(updatedOrder);
      setActivePosition(updatedOrder.position.status);
      handleCloseModal();
    }
    // handleCloseModal();
  };

  console.log(order);
  useEffect(() => {
    setActivePosition(order.position.status);
  }, [order]);

  const getStatusStyles = (status) => {
    switch (status) {
      case "Booked":
        return {
          background: "rgba(103, 60, 142, 0.35)",
          color: "rgba(103, 60, 142, 1)",
          fontSize: "10px",
        };
      case "Processing":
        return {
          background: "rgba(213, 229, 244, 1)",
          color: "rgba(76, 170, 224, 1)",
          fontSize: "10px",
        };
      case "Delivered":
        return {
          background: "rgba(217, 243, 234, 1)",
          color: "rgba(42, 189, 139, 1)",
          fontSize: "10px",
        };
      default:
        return {
          background: "#FFF0ED",
          color: "rgba(254, 133, 106, 1)",
        };
    }
  };
  const styles = getStatusStyles(order.currentStatus.status);
  // ORDER DETAILS COLUMNS----------------------------
  const orderDetailcolumns = [
    {
      id: "puDoId",
      label: "Pickup Id",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              color:
                row.type == "Dropoff"
                  ? "rgba(68, 27, 229, 1)"
                  : "rgba(86, 93, 109, 1)",
            }}
          >
            {row.puDoId}
          </Typography>
        );
      },
    },
    {
      id: "itemName",
      label: "Item Name",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              color:
                row.type == "Dropoff"
                  ? "rgba(68, 27, 229, 1)"
                  : "rgba(86, 93, 109, 1)",
            }}
          >
            {row.itemName}
          </Typography>
        );
      },
    },
    {
      id: "category",
      label: "Category ",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              color:
                row.type == "Dropoff"
                  ? "rgba(68, 27, 229, 1)"
                  : "rgba(86, 93, 109, 1)",
            }}
          >
            {row.category}
          </Typography>
        );
      },
    },
    {
      id: "expectedBy",
      label: "Expected By",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              color:
                row.type == "Dropoff"
                  ? "rgba(68, 27, 229, 1)"
                  : "rgba(86, 93, 109, 1)",
            }}
          >
            {row.expectedBy}
          </Typography>
        );
      },
    },
    {
      id: `${
        orderItems[0]?.type === "Pickup" ? "pickupaddress" : "dropoffaddress"
      }`,
      label: "Address",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              color:
                row.type == "Dropoff"
                  ? "rgba(68, 27, 229, 1)"
                  : "rgba(86, 93, 109, 1)",
            }}
          >
            {row.type === "Pickup"
              ? row.pickupaddress?.slice(0, 36) + "..."
              : row.dropoffaddress?.slice(0, 36) + "..."}
          </Typography>
        );
      },
    },
    {
      id: "amount",
      label: "Amount",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              color:
                row.type == "Dropoff"
                  ? "rgba(68, 27, 229, 1)"
                  : "rgba(86, 93, 109, 1)",
            }}
          >
            {row.amount}
          </Typography>
        );
      },
    },
    {
      id: "paidBy",
      label: "Paid By",
      align: "center",
      renderCell: (row) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              color:
                row.type == "Dropoff"
                  ? "rgba(68, 27, 229, 1)"
                  : "rgba(86, 93, 109, 1)",
            }}
          >
            {row.paidBy}
          </Typography>
        );
      },
    },
  ];
  // ORDER DETAILS COLUMNS----------------------------

  // =============================client waiting time
  const calculateWaitingTime = (dateBooked) => {
    const bookedDate = new Date(dateBooked);
    const now = new Date();
    const diffMs = now - bookedDate; // Difference in milliseconds
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60)); // Convert milliseconds to hours
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes

    return `${diffHrs} Hr ${diffMins} Min`;
  };

  const waitingTime = calculateWaitingTime(order.dateBooked);

  //FUNCTION TO GO TO BACK PAGE
  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    setFiltrationData((prevData) => ({
      ...prevData,
      search: value,
    }));
  };
  return (
    <Grid container sx={{ padding: "0 24px" }} spacing={1}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <IconButton onClick={handleBackClick} sx={{ marginLeft: "-10px" }}>
            <img src={back} />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 6,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: "30px",
                  color: "#673C8E", // Return the value directly
                }}
              >
                Order ID {order.orderId}
              </Typography>
              <img src={order.specialOrder ? star : starOutline} />
            </Box>
            <Box sx={{ marginTop: "12px" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "13px",
                  color: "#673C8E", // Return the value directly
                }}
              >
                Booked : {order.dateBooked}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        justifyContent="end"
        gap={2}
        alignItems="center"
        sx={{ paddingRight: "16px", marginBottom: "20px" }}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{
            padding: "10px 50px",
            textTransform: "capitalize",
            borderColor: styles.background,
            background: styles.background,
            color: styles.color,
            "&:hover": {
              outline: styles.background,
              background: styles.background,
            },
            "&:focus": {
              outline: styles.background,
              background: styles.background,
            },
          }}
        >
          {/* Status */}
          {order.currentStatus.status}
        </Button>
        {/* <Select
          value={activePosition}
          IconComponent={KeyboardArrowDown}
          sx={{
            borderColor: styles.background,
            background: styles.background,
            color: styles.color,
            border: "none",
            fontSize: "12px",
            outline: "none !important",
            "& .MuiSelect-icon": {
              color: styles.color,
            },
            "&:hover": {
              outline: styles.background,
            },
            "&:focus": {
              outline: styles.background,
            },
            "& .MuiSelect-select": {
              padding: "14px 0",
              paddingLeft: "50px",
              paddingRight: "50px !important",
            },
          }}
          onChange={(e) => handlePositionChange(order, e.target.value)}
        >
          {positionOptions.map((position) => (
            <MenuItem
              key={position}
              value={position}
              sx={getPositionStyles(position)}
            >
              {position}
            </MenuItem>
          ))}
        </Select> */}
      </Grid>
      <Grid item xs={12} md={3} mt={2}>
        <Box sx={{ marginTop: "0px", marginLeft: "35px" }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: "13px",
              color: "#673C8E", // Return the value directly
            }}
          >
            Booked Near : {order.bLocation}
          </Typography>
        </Box>
        {/* =========================== CLIENT DETAILS BOX ================================ */}
        <Box
          sx={{
            border: "1px solid #683C8C",
            position: "relative",
            marginTop: "14px",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              color: "#A4A4A4",
              position: "absolute",
              top: "4px",
              left: "10px",
            }}
          >
            Client
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px 0",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Avatar
              alt={client.name}
              src="/static/images/avatar/1.jpg"
              sx={{
                width: 58,
                height: 58,
                color: "#fff",
                background: "rgba(104, 60, 140, 0.35)",
              }}
            />
            <Typography sx={{ fontWeight: 600 }}>
              {client?.customerID}
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                borderColor: "#683C8C",
                padding: "2px 60px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#683C8C",
                  color: "#FFFFFF",
                  borderColor: "#683C8C",
                },
              }}
            >
              {client?.name}
            </Button>
          </Box>
        </Box>
        {/* =========================== CLIENT DETAILS BOX ================================ */}

        {/* =================================== ORDER HISTORY BOX ==================================== */}
        <Box
          sx={{
            marginTop: "15px",
            border: "1px solid #683C8C",
            position: "relative",
            padding: "20px",
            marginBottom: "20px",
            height: "524px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            History
          </Typography>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "80px",
              paddingBottom: "80px",
            }}
          >
            <Typography sx={{ fontSize: "20px", color: "#683C8C" }}>
              No Order History !
            </Typography>
          </Box> */}
          {/* =================================== ORDER HISTORY BOX ==================================== */}

          <Box sx={{ maxWidth: 400 }}>
            <Stepper
              activeStep={3}
              orientation="vertical"
              connector={<CustomStepConnector />}
            >
              {mergedSteps.map((step, index) =>
                step.tooltip ? (
                  <CustomTooltip
                    key={index}
                    title={step.reason || "No reason provided"}
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        background: "#795898",
                        color: "#fff",
                        fontSize: "8px",
                        padding: "8px",
                      },
                      "& .MuiStep-root:hover .MuiTooltip-tooltip": {
                        background: "red",
                      },
                    }}
                  >
                    <Step>
                      <StepLabel icon={step.icon}>
                        <Typography
                          sx={{
                            color: "rgba(104, 60, 140, 1)",
                            fontSize: "13px",
                            fontWeight: 400,
                          }}
                        >
                          {step.label}
                        </Typography>
                        <Typography sx={{ color: "black", fontSize: "10px" }}>
                          {step.description}
                        </Typography>
                      </StepLabel>
                    </Step>
                  </CustomTooltip>
                ) : (
                  <Step>
                    <StepLabel icon={step.icon}>
                      <Typography
                        sx={{
                          color: "rgba(104, 60, 140, 1)",
                          fontSize: "13px",
                          fontWeight: 400,
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography sx={{ color: "black", fontSize: "10px" }}>
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={9}>
        <Box
          sx={{
            marginTop: "-8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              paddingLeft: "14px",
              display: "flex",
              gap: 3,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "13px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Total Pickups {order.tpu}/{order.ppu} Pending
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "13px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Client Waiting - {waitingTime}
            </Typography>
          </Box>
          <Box sx={{ marginRight: "14px", display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                padding: "7px 36px",
                textTransform: "capitalize",
                backgroundColor: "rgba(104, 60, 140, 0.35)",
                color: "#683C8C",
                borderColor: "#683C8C",
                "&:hover": {
                  backgroundColor: "rgba(104, 60, 140, 0.35)",
                  color: "#683C8C",
                  borderColor: "#683C8C",
                },
              }}
            >
              Tips - $00
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                padding: "7px 10px",
                textTransform: "capitalize",
                backgroundColor: "#673C8E",
                color: "#fff",
                borderColor: "#683C8C",
                "&:hover": {
                  backgroundColor: "#673C8E",
                  color: "#fff",
                  borderColor: "#683C8C",
                },
              }}
            >
              Total Amount - ${order.amount}
            </Button>
          </Box>
        </Box>
        {/* ==================================== ORDER ITEMS DATA IN TABLE ====================================== */}
        <DynamicTable
          columns={orderDetailcolumns}
          rows={orderItems}
          searchPlaceholderData={searchInput}
          onSearchData={handleSearchInputChange}
          filtrationData={filtrationData}
          noRecordTitle="No current pickup/dropoff !"
          type="orderDetails"
          otherRow={order}
          paginationProps={{ limit, page, setLimit, setPage, total }}
        />
        {/* ==================================== ORDER ITEMS DATA IN TABLE ====================================== */}

        {/* ==================================  ORDER TRACKING BOX======================================== */}
        <Paper
          elevation={2}
          sx={{
            marginTop: "150px",
            margin: "0 20px",
            padding: "24px",
            marginTop: "-40px",
            marginBottom: "20px",
            height: "450px",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontSize: "20px",
              fontWeight: 600,
              mb: 2,
            }}
          >
            Tracking
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 2,
              height: "240px",
            }}
          >
            <Box
              sx={{
                width: "70%",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                // paddingTop: "80px",
                // paddingBottom: "80px",
                height: "inherit",
              }}
            >
              <MapComponent />
              {/* <Typography sx={{ fontSize: "30px", color: "#683C8C" }}>
                No Current Deliveries !
              </Typography> */}
            </Box>
            <Box
              sx={{
                width: "30%",
                background: "rgba(104, 60, 140, 0.35)",
                padding: "16px",
                height: "inherit",
              }}
            >
              <Typography
                sx={{ color: "#673C8E", fontSize: "17px", fontWeight: 600 }}
              >
                Delivery/Dropoff Notes
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "9px",
                  fontWeight: 400,
                  marginTop: "15px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor
              </Typography>
            </Box>
          </Box>

          {/* ----------------- DELIVERY BY ------------------- */}
          <Box sx={{ position: "absolute", bottom: "20px" }}>
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              Delivery By
            </Typography>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                gap: 3,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Avatar alt="R" />
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Michael
                  </Typography>
                  <Typography
                    sx={{ fontSize: "13px", color: "rgba(99, 99, 99, 1)" }}
                  >
                    ID - 246899
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "start", gap: 2 }}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 2,
                    padding: "13px",
                    width: "205px",
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid rgba(104, 60, 140, 1)",
                      background: "rgba(104, 60, 140, 0.35)",
                      color: "rgba(104, 60, 140, 1)",
                    }}
                  >
                    <PhoneOutlined />
                  </IconButton>
                  <Box>
                    <Typography sx={{ fontSize: "10px" }}>Telephone</Typography>
                    <Typography sx={{ fontSize: "10px", mt: 1 }}>
                      +1 1234567890
                    </Typography>
                  </Box>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 2,
                    padding: "13px",
                    width: "205px",
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid rgba(104, 60, 140, 1)",
                      background: "rgba(104, 60, 140, 0.35)",
                      color: "rgba(104, 60, 140, 1)",
                    }}
                  >
                    <img src={truck} />
                  </IconButton>
                  <Box>
                    <Typography sx={{ fontSize: "10px" }}>
                      Expected Delivery
                    </Typography>
                    <Typography sx={{ fontSize: "10px", mt: 1 }}>
                      23/5/24 - 12:00 AM
                    </Typography>
                  </Box>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 2,
                    padding: "13px",
                    width: "205px",
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid rgba(104, 60, 140, 1)",
                      background: "rgba(104, 60, 140, 0.35)",
                      color: "rgba(104, 60, 140, 1)",
                    }}
                  >
                    <img src={info} />
                  </IconButton>
                  <Box>
                    <Typography sx={{ fontSize: "10px" }}>
                      In Hand Delivery
                    </Typography>
                    <Typography sx={{ fontSize: "10px", mt: 1 }}>
                      Apt Door Delivery
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Box>
        </Paper>
        {/* ==================================  ORDER TRACKING BOX======================================== */}
      </Grid>
      {positionModalOpen && selectedRow && (
        <PositionModal
          ifOrder={false}
          open={positionModalOpen}
          title={positionModalTitle}
          onSubmit={handlePositionSubmit}
          onClose={handleCloseModal}
          btnText={positionBtn}
          placeholder={positionPlaceholder}
        />
      )}
    </Grid>
  );
}
