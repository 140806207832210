import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/images/svg/back.svg";
import defaultBannerImg from "../../assets/images/bannerImg.png";
import upload from "../../assets/images/upload.svg";
import dollar from "../../assets/images/dollar.svg";
import percentIcon from "../../assets/images/percentIcon.svg";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import TextEditor from "../../components/TextEditor";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { $crud } from "../../utils/CrudFactory";
import { BASE_URL } from "../../constants";
import RegularOfferModal from "../../components/modals/RegularOfferModal";
import HelpOfferModal from "../../components/modals/HelpOfferModal";
import { enqueueSnackbar } from "notistack";

export function CreateHelpOffer() {
  const location = useLocation();
  const [offer, setOffer] = useState(null);
  // Extract id from URL
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openHelpOfferModal, setOpenHelpOfferModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // Drag state

  const initialValues = {
    id: "",
    offerName: "",
    discountDetails: "",
    offerDetails: "",
    termCondition: "",
    discountType: "",
    listingPosition: "",
    minOrderValue: "",
    discountValue: "",
    startTime: "",
    startDate: "",
    expireTime: "",
    expireDate: "",
    bannerImg: defaultBannerImg,
    archived: false,
  };

  async function retrieveOffer() {
    const { data } = await $crud.retrieve("admin/help-offer", {
      id,
    });
    return data;
  }
  console.log(offer);
  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    if (id != null) {
      retrieveOffer().then((res) => {
        setOffer(res.offer);
        setValues({
          ...res.offer,
          id: res.offer._id,
        });
      });
    }
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [id]);

  const onSubmit = async (values) => {
    console.log(values, "------------------");
  };

  const handleOpenHelpOfferModal = (row) => {
    setSelectedRow(row);
    setOpenHelpOfferModal(true);
  };

  // function to close position modal---------------------------------
  const handleCloseModal = () => {
    setOpenHelpOfferModal(false);
    setSelectedRow(null);
    navigate("/services/help-offers");
  };

  // function to call the api and update the comment -------------------------------------
  const handleOfferArchiveSubmit = async (reason) => {
    // Logic to update position with reason
    const { data, type } = await $crud.put(`admin/help-offer-archieve`, {
      id: selectedRow._id,
    });
    if (type == "success") {
      // navigate("/services/help-offers");
    }
    // handleCloseModal();
  };

  const handleOfferSubmit = async () => {
    const formData = new FormData();
    formData.append("offerName", values.offerName);
    formData.append("listingPosition", values.listingPosition);
    formData.append("discountDetails", values.discountDetails);
    formData.append("offerDetails", values.offerDetails);
    formData.append("discountType", values.discountType);
    formData.append("minOrderValue", values.minOrderValue);
    formData.append("discountValue", values.discountValue);
    formData.append("startDate", values.startDate);
    formData.append("startTime", values.startTime);
    formData.append("expireDate", values.expireDate);
    formData.append("expireTime", values.expireTime);
    formData.append("archived", values.archived);
    if (offer != []) {
      formData.append("id", values.id);
    }

    // Append the file if it's selected
    if (selectedFile) {
      formData.append("bannerImg", values.bannerImg);
    }

    console.log(values.bannerImg, "===================");
    try {
      const { data } = id
        ? await $crud.put(`admin/help-offer`, formData)
        : await $crud.post(`admin/help-offer`, formData);
      if (data) {
        // console.log("success");

        navigate("/services/help-offers");
      }
    } catch (err) {
      // Handle the error without throwing it further
      console.log("An error occurred while submitting the offer:", err.message);
      // The error has already been handled by the $crud notify method
    }
  };

  // Function to handle file drop
  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false); // Reset drag state
    const file = event.dataTransfer.files[0]; // Get the dropped file
    if (file) {
      handleFileChange({ target: { files: [file] } }); // Use handleFileChange logic
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file size exceeds 1 MB
      if (file.size > 1048576) {
        enqueueSnackbar("Image size should not exceed 1 MB.", {
          variant: "error",
        });

        // Clear selected file if it exceeds size limit
        if (offer !== null) {
          setSelectedFile(null);
        }
        return;
      }

      // Create a URL for the file and update state if the size is within limit
      const fileUrl = URL.createObjectURL(file);
      setSelectedFile(fileUrl);
      setFieldValue("bannerImg", file); // Update Formik field with the file object
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format.").required("Required!"),
    password: Yup.string().required("Required!"),
  });
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const handleBackClick = () => {
    navigate(-1);
  };
  // console.log(values);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container sx={{ padding: "0 18px", position: "relative" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: 1,
            }}
          >
            <IconButton onClick={handleBackClick} sx={{ marginLeft: "-10px" }}>
              <img src={back} />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Help Offers
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ width: "100%", marginTop: "42px" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 500,
                fontSize: "22px",
                color: "#673C8E",
                marginBottom: "13px",
              }}
            >
              Description<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                padding: "38px 43px",
                border: "1px solid #673C8E",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "2px",
                }}
              >
                Offer Name
              </Typography>
              <TextField
                name="offerName"
                placeholder="VPICKUP12B"
                sx={{
                  color: "#1C1B1F",
                  mb: 1,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                size="small"
                fullWidth
                value={values.offerName}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={touched.offerName && !!errors.offerName}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "2px",
                }}
              >
                Discount Details
              </Typography>
              <TextField
                name="discountDetails"
                placeholder="50% OFF"
                sx={{
                  color: "#1C1B1F",
                  mb: 1,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                size="small"
                fullWidth
                value={values.discountDetails}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />
              <TextEditor
                type="helpOffer"
                value={values.offerDetails}
                setFieldValue={setFieldValue}
              />
            </Box>

            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "22px",
                color: "#673C8E",
                marginBottom: "13px",
                marginTop: "30px",
              }}
            >
              Discount Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                padding: "24px 43px",
                border: "1px solid #673C8E",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  marginBottom: "2px",
                }}
              >
                Discount Type
              </Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.discountType}
                  name="discountType"
                  onChange={(e) =>
                    setFieldValue("discountType", e.target.value)
                  }
                  sx={{
                    mt: 1,
                    color: "#1C1B1F",
                    mb: 1,
                    "& .MuiSelect-select": {
                      borderColor: "#673C8E",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                  }}
                  // onChange={handleChange}
                >
                  <MenuItem value="percentOff">Percent Off</MenuItem>
                  <MenuItem value="fixedValue">Fixed Value</MenuItem>
                </Select>
              </FormControl>
              {values.discountType == "fixedValue" && (
                <>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 700,
                      marginBottom: "4px",
                      mt: 1,
                    }}
                  >
                    Fixed Amount Value
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        background: "#673C8E",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={dollar} />
                    </Box>
                    <TextField
                      name="minOrderValue"
                      placeholder="400"
                      type="number"
                      sx={{
                        color: "#1C1B1F",
                        mb: 1,
                        "& fieldset": {
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        },
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important",
                          borderTopLeftRadius: 0,
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1C1B1F",
                          },
                        },
                      }}
                      size="small"
                      fullWidth
                      value={values.minOrderValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                    />
                  </Box>
                </>
              )}

              {values.discountType == "percentOff" && (
                <>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 700,
                      marginBottom: "4px",
                      mt: 1,
                    }}
                  >
                    Discount Percentage
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      name="discountValue"
                      placeholder="10"
                      type="number"
                      sx={{
                        color: "#1C1B1F",
                        mb: 1,
                        "& fieldset": {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important",
                          borderTopLeftRadius: 0,
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1C1B1F",
                          },
                        },
                      }}
                      size="small"
                      fullWidth
                      value={values.discountValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                    />
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        background: "#673C8E",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={percentIcon} />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "22px",
                color: "#673C8E",
                marginBottom: "13px",
                marginTop: "30px",
              }}
            >
              Offer Validity Period<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                padding: "24px 43px",
                border: "1px solid #673C8E",
                borderRadius: "4px",
                marginBottom: "48px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "6px",
                }}
              >
                Starting On
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  mb: 2,
                  gap: 3,
                }}
              >
                <LocalizationProvider
                  sx={{
                    "& .PrivatePickersPopperRoot-popupIndicator": {
                      display: "none",
                    },
                  }}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    value={values?.startDate ? dayjs(values.startDate) : null}
                    onChange={(newValue) => {
                      const formattedDate = dayjs(newValue).toISOString();
                      setFieldValue("startDate", formattedDate);
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px",
                        borderRadius: "4px",
                        border: "none", // Remove the border from the input
                      },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                        color: "#673C8E", // Change the color of the icon button
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        border: "2px solid #673C8E",
                      },
                      "& .MuiOutlinedInput-root fieldset:hover": {
                        borderColor: "#673C8E", // Change border color on hover
                      },
                      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#673C8E", // Change border color when focused
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={
                      values?.startTime
                        ? dayjs(values.startTime, "HH:mm")
                        : null
                    }
                    onChange={(newValue) => {
                      const formattedTime = dayjs(newValue?.$d).format("HH:mm");
                      setFieldValue("startTime", formattedTime);
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px",
                        borderRadius: "4px",
                        border: "none", // Remove the border from the input
                      },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                        color: "#673C8E", // Change the color of the icon button
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        border: "2px solid #673C8E",
                      },
                      "& .MuiOutlinedInput-root fieldset:hover": {
                        borderColor: "#673C8E", // Change border color on hover
                      },
                      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#673C8E", // Change border color when focused
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>

              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "6px",
                }}
              >
                Expires On
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <LocalizationProvider
                  sx={{
                    "& .PrivatePickersPopperRoot-popupIndicator": {
                      display: "none",
                    },
                  }}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    value={values?.expireDate ? dayjs(values.expireDate) : null}
                    onChange={(newValue) => {
                      const formattedDate = dayjs(newValue).toISOString();
                      setFieldValue("expireDate", formattedDate);
                    }}
                    minDate={values?.startDate ? dayjs(values.startDate) : null}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px",
                        borderRadius: "4px",
                        border: "none", // Remove the border from the input
                      },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                        color: "#673C8E", // Change the color of the icon button
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        border: "2px solid #673C8E",
                      },
                      "& .MuiOutlinedInput-root fieldset:hover": {
                        borderColor: "#673C8E", // Change border color on hover
                      },
                      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#673C8E", // Change border color when focused
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={
                      values?.expireTime
                        ? dayjs(values.expireTime, "HH:mm")
                        : null
                    }
                    onChange={(newValue) => {
                      const formattedTime = dayjs(newValue?.$d).format("HH:mm");
                      setFieldValue("expireTime", formattedTime);
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px",
                        borderRadius: "4px",
                        border: "none", // Remove the border from the input
                      },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                        color: "#673C8E", // Change the color of the icon button
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        border: "2px solid #673C8E",
                      },
                      "& .MuiOutlinedInput-root fieldset:hover": {
                        borderColor: "#673C8E", // Change border color on hover
                      },
                      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#673C8E", // Change border color when focused
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* ---------------preview banner image */}
          <Box
            sx={{
              width: "100%",
              marginTop: "42px",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "18px",
              paddingRight: "24px",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "22px",
                color: "#673C8E",
              }}
            >
              Preview Banner Image
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "310px",
                overflow: "hidden", // Ensure the image fits within the box without stretching
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Optional: adds a shadow effect
              }}
            >
              <img
                src={
                  selectedFile ||
                  (offer
                    ? `${BASE_URL}helpOffers/${values.bannerImg}`
                    : defaultBannerImg)
                }
                // src={
                //   // If offer exists, show the existing banner image or selected new image
                //   offer
                //     ? values.bannerImg
                //       ? `${BASE_URL}helpOffers/${values.bannerImg}`
                //       : selectedFile
                //     : // If offer does not exist (creating a new offer), show default banner image
                //       selectedFile || defaultBannerImg
                // }
                // src={
                //   offer
                //     ? `${BASE_URL}helpOffers/${values.bannerImg}`
                //     : selectedFile || values.bannerImg
                // }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // Ensure the image covers the box without distortion
                }}
              />
            </Box>
          </Box>
          {/* ---------------preview banner image */}

          {/* -----------------Upload banner image--------------- */}
          <Box
            sx={{
              width: "100%",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingLeft: "18px",
              paddingRight: "24px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "22px",
                color: "#673C8E",
              }}
            >
              Upload Banner Image<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "188px",
                background: "#F8F9FB",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                border: isDragging ? "2px dashed #673C8E" : "none", // Dashed border when dragging
              }}
              onClick={() => fileInputRef.current.click()} // Handle click to open file dialog
              onDragOver={(e) => {
                e.preventDefault(); // Prevent default behavior (open in new tab)
                setIsDragging(true); // Indicate dragging
              }}
              onDragLeave={() => setIsDragging(false)} // Remove dragging state on drag leave
              onDrop={handleFileDrop} // Handle drop event
            >
              <img src={upload} width="24" height="24" />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  fontSize: "19px",
                  color: "#673C8E",
                }}
              >
                Click to upload or Drag and drop here
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  fontSize: "19px",
                  color: "#000",
                }}
              >
                Max.File Size 1MB (1050 x 495)
              </Typography>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </Box>
          </Box>
          {/* ---------------Upload banner image------------------- */}

          {/* -------------offer valid for--------------- */}
          <Box
            sx={{
              width: "100%",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingLeft: "18px",
              paddingRight: "24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                marginBottom: "2px",
              }}
            >
              Listing Position<span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl fullWidth>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.listingPosition || 0}
                name="discountType"
                onChange={(e) =>
                  setFieldValue("listingPosition", e.target.value)
                }
                sx={{
                  mt: 1,
                  color: "#1C1B1F",
                  mb: 1,
                  "& .MuiSelect-select": {
                    borderColor: "#673C8E",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#673C8E",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#673C8E",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#673C8E",
                  },
                }}
                // onChange={handleChange}
              >
                <MenuItem value={0}>
                  Select The Position Of Offer e.g-1,2
                </MenuItem>
                <MenuItem value={1}>1st Container</MenuItem>
                <MenuItem value={2}>2nd Container</MenuItem>
                <MenuItem value={3}>3rd Container</MenuItem>
                <MenuItem value={4}>4th Container</MenuItem>
                <MenuItem value={5}>5th Container</MenuItem>
                <MenuItem value={6}>6th Container</MenuItem>
                <MenuItem value={7}>7th Container</MenuItem>
                <MenuItem value={8}>8th Container</MenuItem>
                <MenuItem value={9}>9th Container</MenuItem>
                <MenuItem value={10}>10th Container</MenuItem>
                <MenuItem value={11}>11th Container</MenuItem>
                <MenuItem value={12}>12th Container</MenuItem>
                <MenuItem value={13}>13th Container</MenuItem>
                <MenuItem value={14}>14th Container</MenuItem>
                <MenuItem value={15}>15th Container</MenuItem>
                <MenuItem value={16}>16th Container</MenuItem>
                <MenuItem value={17}>17th Container</MenuItem>
                <MenuItem value={18}>18th Container</MenuItem>
                <MenuItem value={19}>19th Container</MenuItem>
                <MenuItem value={20}>20th Container</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* ----------------action buttons--------------------- */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            paddingRight: "24px",
            gap: 2,
            position: "absolute",
            right: 0,
            bottom: "30px",
          }}
        >
          {id && (
            <Button
              variant="outlined"
              size="small"
              sx={{
                padding: "10px 30px",
                textTransform: "capitalize",
                backgroundColor: "transparent",
                color: "rgba(103, 60, 142, 1)",
                borderColor: "rgba(103, 60, 142, 1)",
                borderRadius: "4px",
                fontWeight: 600,
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "rgba(103, 60, 142, 1)",
                  borderColor: "rgba(103, 60, 142, 1)",
                },
              }}
              onClick={(e) => handleOpenHelpOfferModal(offer)}
            >
              {offer && offer.archived ? "Unarchive" : "Archive"}
            </Button>
          )}
          <Button
            variant="outlined"
            size="small"
            onClick={handleBackClick}
            sx={{
              padding: "10px 30px",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              color: "rgba(103, 60, 142, 1)",
              borderColor: "rgba(103, 60, 142, 1)",
              borderRadius: "4px",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "transparent",
                color: "rgba(103, 60, 142, 1)",
                borderColor: "rgba(103, 60, 142, 1)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              padding: "10px 30px",
              textTransform: "capitalize",
              backgroundColor: "rgba(103, 60, 142, 1)",
              color: "#fff",
              borderRadius: "4px",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "rgba(103, 60, 142, 1)",
                color: "#fff",
              },
            }}
            // type="submit"
            onClick={handleOfferSubmit}
          >
            {id ? "Update" : " Create Offer"}
          </Button>
        </Box>
        {/* ----------------action buttons--------------------- */}
      </Grid>
      {openHelpOfferModal && selectedRow && (
        <HelpOfferModal
          open={openHelpOfferModal}
          archived={selectedRow.archived}
          onSubmit={handleOfferArchiveSubmit}
          onClose={handleCloseModal}
        />
      )}
    </form>
  );
}
