import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Grid,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  FormHelperText,
} from "@mui/material";
import { API_URL } from "../../constants";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import loginMain from "../../assets/images/loginMain.png";
import login2 from "../../assets/images/login2.svg";
import errorImg from "../../assets/images/error.svg";
import logo from "../../assets/images/logo.svg";

// Utility functions for cookies
const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const eraseCookie = (name) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};

export function LoginPage() {
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckRemember, setIsCheckRemember] = useState(false);
  const [errorType, setErrType] = useState(null);
  const navigate = useNavigate();

  // INITIAL VALUES
  // const initialValues = {
  //   email: "",
  //   password: "",
  // };
  const initialValues = {
    email: getCookie("savedEmail") || "",
    password: getCookie("savedPassword") || "",
  };

  useEffect(() => {
    // Check if cookies are present for "Remember Me"
    const savedEmail = getCookie("savedEmail");
    const savedPassword = getCookie("savedPassword");
    if (savedEmail && savedPassword) {
      setIsChecked(true);
      setIsCheckRemember(true); // Set the checkbox as checked if cookies exist
    }
  }, []);

  // SUBMIT FUNCTION TO CALL THE LOGIN API ---------------------------------
  const onSubmit = async (values) => {
    let snackbarKey;

    try {
      const response = await axios.post(`${API_URL}create/admin/login`, values);
      const { data, type, message } = response.data;

      if (type === "error") {
        // Show the error message and store the snackbar key
        snackbarKey = enqueueSnackbar(message, {
          variant: type,
          persist: true,
        });
        console.log(snackbarKey, "snack when error ");
        setErrType("error");
      }

      if (data) {
        // Dismiss the error snackbar if it exists
        closeSnackbar(snackbarKey);

        // Redirect to /authenticator route
        navigate("/authenticator", {
          state: {
            qrCodeDataURL: data.qrCodeDataURL,
            otpSecret: data.otpSecret,
            userId: data.loginUser._id,
            token: `Bearer ${data.token}`,
            user: JSON.stringify(data?.loginUser),
            isQRScanned: data?.isQRScanned,
            changeMFA: false,
          },
        });

        // Handle "Remember Me" logic
        if (isCheckRemember) {
          setIsChecked(true);
          setCookie("savedEmail", values.email, 7); // Cookie expires in 7 days
          setCookie("savedPassword", values.password, 7);
        } else {
          eraseCookie("savedEmail");
          eraseCookie("savedPassword");
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrType("error");
      enqueueSnackbar("Login failed. Please try again.", { variant: "error" });
    }
  };

  // SUBMIT FUNCTION TO CALL THE LOGIN API ---------------------------------

  // FRONTEND VALIDATIONS ------------------------
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format.").required("Required!"),
    password: Yup.string().required("Required!"),
  });
  // FRONTEND VALIDATIONS ------------------------

  useEffect(() => {
    // Push a dummy state
    window.history.pushState(null, null, window.location.pathname);

    const handlePopState = () => {
      // Prevent back navigation by pushing state
      window.history.pushState(null, null, window.location.pathname);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  const handleCheckBtn = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      // Email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (value.length >= 8 && emailRegex.test(values.email)) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    }
  };

  // console.log(isChecked, "--------------------------");
  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  const [showPassword, setShowPassword] = useState(false);

  // SHOW/HIDE PASSWORD FUNCTION-----------------------
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // SHOW/HIDE PASSWORD FUNCTION-----------------------

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            height: "100vh",
            padding: "0",
            width: "100vw",
            display: "flex",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ position: "absolute", right: "20px", top: "10px" }}>
            <img style={{ width: "120px" }} src={logo} alt="Logo" />
          </Box>
          <Grid container>
            <Grid
              item
              xs={12}
              md={7.5}
              sx={{
                background: (theme) => theme.palette.background.paper,
                boxShadow: "0px 4px 10px 0px #673C8E",
                borderRight: "1px solid #673C8E",
                display: "grid",
                placeItems: "center",
              }}
            >
              {/* ================================= LOGIN BOX ============================== */}
              <Box
                sx={{
                  maxWidth: {
                    xs: "270px",
                    md: "300px",
                    lg: "512px",
                  },
                  marginTop: {
                    xs: "40px",
                    md: "0",
                  },
                }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: 600,
                      }}
                    >
                      Login
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#313131",
                        marginTop: "16px",
                      }}
                    >
                      Login to access your Vpickup account
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      placeholder="Enter Your Email"
                      sx={{
                        color: "#1C1B1F",
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important", // Adding !important to ensure the color overrides
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary, // Change outlined border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1C1B1F", // Change outlined border color when focused
                          },
                        },
                      }}
                      size="small"
                      label="Email"
                      id="email"
                      fullWidth
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <InputLabel
                        sx={{
                          color: "#1C1B1F !important", // Change label text color
                        }}
                        size="small"
                        htmlFor="outlined-adornment-password"
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        placeholder="Enter Password"
                        sx={{
                          color: "#1C1B1F",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary, // Change outlined border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary, // Change outlined border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#1C1B1F", // Change outlined border color when focused
                          },
                        }}
                        size="small"
                        id="password"
                        name="password"
                        value={values.password}
                        onChange={(e) => {
                          handleChange(e);
                          handleCheckBtn(e);
                        }}
                        onBlur={handleBlur}
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        error={touched.password && !!errors.password}
                      />
                      {touched.password && (
                        <FormHelperText error>{errors.password}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCheckRemember}
                            onChange={(e) =>
                              setIsCheckRemember(e.target.checked)
                            }
                            sx={{
                              color: "#683C8C",
                              "&.Mui-checked": {
                                color: "#683C8C",
                              },
                            }}
                          />
                        }
                        label="Remember me"
                      />
                      <Typography
                        onClick={() =>
                          navigate("/forgot-password", {
                            state: { changeAccPassword: false },
                          })
                        }
                        sx={{ cursor: "pointer", fontWeight: 500 }}
                        variant="body2"
                        color="error"
                      >
                        Forgot Password
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant={isChecked ? "contained" : "outlined"}
                      fullWidth
                      size="small"
                      type="submit"
                      sx={{
                        backgroundColor: isChecked ? "#683C8C" : "transparent",
                        color: isChecked ? "#FFFFFF" : "#683C8C",
                        borderColor: "#683C8C", // for outlined variant
                        padding: "10px 8px",
                        "&:hover": {
                          backgroundColor: isChecked
                            ? "#56287A"
                            : "transparent",
                          borderColor: "#56287A", // for outlined variant hover
                          color: isChecked ? "#FFFFFF" : "#56287A",
                        },
                      }}
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {/* ================================= LOGIN BOX ============================== */}
            </Grid>
            <Grid
              item
              xs={12}
              md={4.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{ position: "relative" }}
            >
              {/* =========================== LOGIN IMAGE BOX ============================== */}
              {errorType === "error" ? (
                <Box
                  sx={{
                    marginTop: { xs: "30px", md: "0" },
                    width: { xs: "150px", md: "250px", lg: "380px" },
                  }}
                >
                  <img
                    style={{ width: "inherit" }}
                    src={errorImg}
                    alt="Login Error"
                  />
                </Box>
              ) : isChecked ? (
                <Box
                  sx={{
                    marginTop: { xs: "30px", md: "0" },
                    width: { xs: "150px", md: "250px", lg: "380px" },
                  }}
                >
                  <img style={{ width: "inherit" }} src={login2} alt="Login" />
                </Box>
              ) : (
                <Box
                  sx={{
                    marginTop: { xs: "30px", md: "0" },
                    width: { xs: "150px", md: "250px", lg: "380px" },
                  }}
                >
                  <img
                    style={{ width: "inherit" }}
                    src={loginMain}
                    alt="Login"
                  />
                </Box>
              )}
              {/* =========================== LOGIN IMAGE BOX ============================== */}
              <Typography
                variant="h4"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  marginTop: "30px",
                  fontWeight: "600",
                }}
              >
                Vpickup Admin
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}
